import React, {useState} from 'react';
import {isEmail} from "../utils/common-utils.ts";
import ReactDOM from "react-dom/client";

export const renderFormVideoHelpFr = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><FormVideoHelpFr/></React.StrictMode>)
    }
}
const FormVideoHelpFr = () => {
    const [choiceOS, setChoiceOS] = useState('- Choisir -');
    const [choiceProv, setChoiceProv] = useState('- Choisir -');
    const [connection, setConnection] = useState('');
    const [autreOS, setAutreOS] = useState('');
    const [browser, setBrowser] = useState('');
    const [browserAutre, setBrowserAutre] = useState('');
    const [city, setCity] = useState('');
    const [errorDetails, setErrorDetails] = useState('');
    const [emailAddress, setEmailAddress] = useState('');


    const renderTextField = (inputData: { name: any; id: any; label: any; type: any; value?: string; required: any; ariaRequired: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>{inputData.required}</span> : ''}</label>
                <div className='input_container input_container_text'>
                    <input name={inputData.name}
                           id={inputData.id}
                           type={inputData.type}
                           value={value}
                           className='medium'
                           aria-invalid="false"
                           aria-required={inputData.ariaRequired}
                           aria-describedby={inputData.description}
                           onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderTextArea = (inputData: { name: any; id: any; label: any; required: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_textarea'>
                <textarea name={inputData.name}
                          id={inputData.id}
                          className='textarea medium'
                          value={value}
                          aria-invalid="false" rows={10} cols={50}
                          onChange={(e) => onChange(e.target.value)}/>
                </div>
            </>
        );
    }

    const renderEmailField = (inputData: { name: any; id: any; label: any; required: any; description: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.id}
                        type='email'
                        value={value}
                        className='medium'
                        aria-required="true" aria-invalid="false" aria-describedby="field_description_email"
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                </div>
                <div className={`sp_field_description ${isEmail(value) ? "" : "invalid"}`}
                     id="field_description_email">{!isEmail(value) ? inputData.description : "Merci"}</div>
            </>
        );
    }

    const renderDropdown = (inputData: { name: any; id: any; label: any; option: any; required: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_select'>
                    <select
                        name={inputData.name}
                        id={inputData.id}
                        className='medium field_select'
                        aria-required="true"
                        aria-invalid="false"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        {inputData.option.map(({value, label}: any, i: any) => <option key={`${inputData.name}-${i}`}
                         value={value}>{label}</option>)}
                    </select>
                </div>
            </>
        )
    }

    const renderChoiceOs = () => {
        const inputData = {
            name: 'operating-system-choice',
            id: 'OSselector',
            label: 'Type de système d\'exploitation',
            option: [
                {label: '- Choisir -', value: '- Choisir -'},
                {label: 'Windows', value: 'Windows'},
                {label: 'Mac', value: 'Mac'},
                {label: 'Linux', value: 'Linux'},
                {label: 'Apple iOS - iPad / iPhone', value: 'Apple iOS - iPad / iPhone'},
                {label: 'Andriod', value: 'Andriod'},
                {label: 'Autre', value: 'Autre'},
            ],
            required: true,
        };

        return renderDropdown(inputData, choiceOS, setChoiceOS);
    }

    const renderAutreOS = () => {
        const inputData = {
            name: 'autreOS',
            id: 'otherOS',
            label: 'Système d\'exploitation - Autre',
            type: 'text',
            value: '',
            required: false,
            ariaRequired: 'false',
            description: 'field_description_OSother',
            message: 'Si votre système d\'exploitation ne figure pas dans la liste, veuillez indiquer le nom de votre système d\'exploitation.'
        }
        return renderTextField(inputData, autreOS, setAutreOS);
    }

    const renderBrowser = () => {
        const inputData = {
            name: 'browser',
            id: 'browserSelect',
            label: 'Type de navigateur',
            required: true,
            option: [
                {label: '- Choisir -', value: '- choisir -'},
                {label: 'Edge', value: 'Edge'},
                {label: 'Firefox', value: 'Firefox'},
                {label: 'Safari', value: 'Safari'},
                {label: 'Chrome', value: 'Chrome'},
                {label: 'Autre', value: 'Autre'},
            ]
        };

        return renderDropdown(inputData, browser, setBrowser);
    }

    const renderBrowserAutre = () => {
        const inputData = {
            name: 'browser_autre',
            id: 'other-browser',
            label: 'Navigateur - Autre',
            type: 'text',
            value: '',
            required: false,
            ariaRequired: 'false',
            description: 'field_description_browserOther',
            message: 'Si votre navigateur Web ne figure pas dans la liste, veuillez indiquer le nom de votre navigateur.'
        }
        return renderTextField(inputData, browserAutre, setBrowserAutre);
    }

    const renderCity = () => {
        const inputData = {
            name: 'city',
            id: 'location',
            label: 'Votre ville',
            type: 'text',
            value: '',
            required: true,
            ariaRequired: 'true',
            description: 'field_description_city',
            message: 'Veuillez indiquer votre emplacement.'
        }
        return renderTextField(inputData, city, setCity);
    }

    const renderChoiceProv = () => {
        const inputData = {
            name: 'province-territory',
            id: 'province',
            label: 'Votre province / territoire',
            option: [
                {label: '- Choisir -', value: '- Choisir -'},
                {label: 'Colombie-britannique', value: 'Colombie-britannique'},
                {label: 'Alberta', value: 'Alberta'},
                {label: 'Saskatchewan', value: 'Saskatchewan'},
                {label: 'Manitoba', value: 'Manitoba'},
                {label: 'Ontario', value: 'Ontario'},
                {label: 'Québec', value: 'Québec'},
                {label: 'Nouveau-Brunswick', value: 'Nouveau-Brunswick'},
                {label: 'Île-du-Prince-Édouard', value: 'Île-du-Prince-Édouard'},
                {label: 'Nouvelle-Écosse', value: 'Nouvelle-Écosse'},
                {label: 'Terre-Neuve-et-Labrador', value: 'Terre-Neuve-et-Labrador'},
                {label: 'Nunavut', value: 'Nunavut'},
                {label: 'Territoires du Nord-Ouest', value: 'Territoires du Nord-Ouest'},
                {label: 'Yukon', value: 'Yukon'},
            ],
            required: true,
        };

        return renderDropdown(inputData, choiceProv, setChoiceProv);
    }

    const renderConnection = () => { 
        const inputData = {
            name: 'internet-connection',
            id: 'connection',
            label: 'Type de connexion',
            required: true,
            option: [
                {label: '- Choisir -', value: '- choisir -'},
                {label: 'Modem câble', value: 'Cable'},
                {label: 'Fibre optique', value: 'Fibre'},
                {label: 'Satellite', value: 'Satellite'},
                {label: 'Sans fil fixe', value: 'Fixed Wireless'},
                {label: 'Mobile', value: 'Mobile'},
                {label: 'DSL (Digital Subscriber Line - ligne d\'accès numérique)', value: 'DSL'},
            ]
        };

        return renderDropdown(inputData, connection, setConnection);
    }

    const renderErrorDetails = () => {
        const inputData = {
            name: 'error_details',
            id: 'errorDetails',
            label: 'Veuillez expliquer en détails les difficultés que vous éprouvez.',
            required: false,
        }
        return renderTextArea(inputData, errorDetails, setErrorDetails);
    }

    const renderEmailDetails = () => {
        const inputData = {
            name: 'email',
            id: 'emailAddress',
            label: 'Courriel',
            required: true,
            description: 'Veuillez fournir votre adresse électronique',
        }
        return renderEmailField(inputData, emailAddress, setEmailAddress);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const mailSubject = "?subject=Submission d'Aide videos";
        const mailBody = `&body=Adresse courriel : ${emailAddress}%0D%0A Ville : ${city}%0D%0A Province : ${choiceProv}%0D%0A Type de systeme d'exploitation : ${choiceOS}%0D%0A Systeme d'exploitation - Autre : ${autreOS}%0D%0A Type de navigateur : ${browser}%0D%0A Navigateur - Autre : ${browserAutre}%0D%0A Type de connexion : ${connection}%0D%0A Details des difficultes : ${errorDetails}%0D%0A`;
        const sendMail = "mailto:videohelp@cpac.ca";
        window.open(sendMail + mailSubject + mailBody);
    }

    return (
        <>
            { 
                 <div id="form_wrapper" className="form_wrapper">
                     <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form_body">
                            {renderChoiceOs()}
                            {choiceOS === 'Autre' ? renderAutreOS() : ''}
                            {renderBrowser()}
                            {browser === 'Autre' ? renderBrowserAutre() : ''}
                            {renderCity()}
                            {renderChoiceProv()}
                            {renderConnection()}
                            {renderErrorDetails()}
                            {renderEmailDetails()}
                        </div>
                        <div className='form_footer left_label'>
                            <button type="submit" name='submit' className="btn--primary">Envoyer par courrier électronique</button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default FormVideoHelpFr;