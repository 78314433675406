import {ssg, sss} from "../utils/common-utils.ts";
import cpacStore from "../cpac-store.ts";
import {Language} from "./Language.ts";

export const initUserLanguage = () => {
    const el = document.querySelector("html")
    if (el instanceof HTMLHtmlElement) {
        if (el.lang == "en") {
            cpacStore.setLanguage(Language.en)
        } else if (el.lang == "fr") {
            cpacStore.setLanguage(Language.fr)
        }
    }
}

export const loadLocaleStrings = () => {
    // stored in ss?
    let strings = ssg("locale-strings")
    if (strings == null) {
        // no - go get it
        fetch("/api/1/services/cpac/site/locale-strings.json")
            .then(res => res.json())
            .then(json => {
                // store in ss
                strings = JSON.stringify(json)
                sss("locale-strings", strings);
                // add to store
                const map = new Map(Object.entries(json)) as Map<string, string>;
                cpacStore.setLocaleStrings(map)
            })
    } else {
        // yes - just add to map
        const map = new Map(Object.entries(JSON.parse(strings as string))) as Map<string, string>;
        cpacStore.setLocaleStrings(map)
    }
}

export const getLabel = (key: string) => {
    const langlabel = `${key}_${cpacStore.getState().lang}`;
    const map = cpacStore.getState().localeStrings
    return map.get(langlabel)
}

export const getLabelForLang = (key: string, lang: Language) => {
    return cpacStore.getState().localeStrings.get(`${key}_${lang.toString()}`)
}


export const getLanguage = (): Language => {
    return cpacStore.getState().lang
}