export class Tag {
    constructor(readonly url: string, readonly name: string) {
    }

    static createArrayFromDOM(map: DOMStringMap, lang: string) {
        const arr = new Array<Tag>();
        for (let idx = 0; true; idx++) {
            let nameKey = "tag_" + idx + "_name_en_s"
            let urlKey = "tag_" + idx + "_url_en_t"
            if (lang == "fr") {
                nameKey = "tag_" + idx + "_name_fr_s"
                urlKey = "tag_" + idx + "_url_fr_t"
            }

            if (map[nameKey]) {
                const n = map[nameKey]
                const u = map[urlKey]
                if (n && u) {
                    arr.push(new Tag(u, n))
                }
            } else {
                break;
            }
        }
        return arr;
    }

}
