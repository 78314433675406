import styled from 'styled-components';

const VCarouselHolder = styled.div`
  margin-top: 40px;

  * {
    margin: 0;
  }

  h2 {
    margin-bottom: 8px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
    color: inherit;
    font-family: "Quicksand", sans-serif;

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: landscape)
    and (-webkit-min-device-pixel-ratio: 1) {
      font-size: 15px;
      margin-right: 15px;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;

    &:hover {
      color: black;
    }

    img {
      width: 100%;
      height: 65px;
      object-fit: cover;
    }

    &.seeAll-btn {
      width: 100%;
      font-size: 0.9rem;
      color: white;
      background-color: #7a7652;
      border: 5px solid #d6d2c8;
      border-radius: 5px;
      padding: 0.7rem 1.6rem;
      font-size: 0.9rem;
      font-weight: 700;
      letter-spacing: .025em;
      text-align: center;
      cursor: pointer;
      margin-right: 10px;
      min-width: 110px;
      text-transform: uppercase;

      &:hover {
        border: 5px solid #7a7652;
      }
    }
  }

  .seeAllTopBtn {
    float: right;
    color: #7a7652;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      color: #5f7586;

      svg {
        right: 0px;
      }
    }

    svg {
      position: relative;
      margin-left: 15px;
      right: 8px;
      transition: right 0.2s ease-in;
    }
  }

  .latest-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto, 1fr);
    gap: 8px 0px;
    grid-template-areas:
		"."
		"."
		"."
		"."
		".";
    margin-bottom: 20px;
    border-top: 1px solid #a69e87;
    padding: 0px;
    padding-top: 15px;

    li {
      list-style: none;
    }

    &__item {
      display: grid;
      grid-template-columns: 115px auto;
      grid-template-rows: 1fr;
      gap: 10px 15px;
      grid-template-areas:
			  ". .";
      padding-bottom: 25px;
      text-decoration: none;
      border-bottom: 1px solid #a69e87;

      p {
        margin: 0;
        padding: 0;
      }

      &__img {
        position: relative;
        width: 100%;

        &__date-container {
          width: 50%;
          background-color: rgba(0, 0, 0, 0.6);
          opacity: 0.8;
          color: white;
          text-align: center;
          margin-top: -24px;
          float: right;
          font-family: "Quicksand", sans-serif;
          font-size: 13px;
          padding: 2px;
          font-weight: 500;
        }
      }

      &__info {
        &__category {
          color: #5f7586;
          font-size: 12px !important;
          font-weight: 700;
          text-transform: uppercase;
          margin: 0 0 5px 0 !important;
        }

        &__title {
          display: block;
          color: #383838;
          font-size: 14px;
          line-height: 1.2;
        }
      }

    }

    &__btns-container {
      display: flex;
      justify-content: center;

      button {
        font-weight: 700;
        padding: 12px 20px;
        vertical-align: middle;
        font-size: 14px;
        color: #726b55;
        background-color: #fdfdfc;
        border-radius: 4px;
        -webkit-transition: all 0.1s ease-in;
        transition: all 0.1s ease-in;
        border: 1px solid #d1cdc1;
        line-height: 1;
        -webkit-transition: all 0.1s ease-in;
        transition: all 0.1s ease-in;
        margin-right: 10px;
        min-height: 48px;
        cursor: pointer;

        svg {
          padding: 0;
          margin: -2px 8px 0 8px;
          vertical-align: middle;
          line-height: 1;
        }

        &:last-of-type {
          margin-right: 0;
        }

        &:hover {
          background-color: #a69e87 !important;
          color: #fdfdfc !important;
          transition: all 0.1s ease-in;
        }

        &:disabled {
          opacity: 0.4;
          cursor: no-drop;
        }

      }

    }

  }
`;

export default VCarouselHolder;