import {ItemListViewProps} from "../../types/ItemListViewProps.ts";
import {useEffect, useState} from 'react';

import HCarouselHolder from './HCarouselStyles.tsx';
import {resizeElement} from "../../utils/responsive.ts";
import {loadAppropriateImages} from "../../utils/image-utils.ts";

const HCarousel = (props: ItemListViewProps) => {
    const {itemList, fadeOutSeconds} = props
    const [selectedIndex, setSelectedIndex] = useState(itemList.items.length >= 4 ? 3 : 0)

    useEffect(() => {
        const timeout = fadeOutSeconds == undefined ? 5000 : fadeOutSeconds * 1000;
        setInterval(() => {
            showNext()
        }, (timeout));
    }, []);

    useEffect(() => {
        resizeElement('.carousel-img');
        loadAppropriateImages()
    });

    const showNext = () => {
        setSelectedIndex(selectedIndex => {
            if (itemList.items.length < 4) {
                return 0
            } else if (itemList.items.length == 4) {
                return 3 //4
            } else {
                let numToShow = selectedIndex + 1
                if (numToShow == itemList.items.length) {
                    numToShow = 3;
                }
                return numToShow
            }
        })
    }


    const renderItems = () => {
        return itemList.items.map((item, i) => {
            if (i < 3 && item.image) {
                return (
                    <a className="carousel-img" key={i} href={item.url}
                       target={item.openInNewTab ? "_blank" : "_self"} itemProp='itemListElement'>
                        <img loading="lazy" className="multi-thumb" data-src={item.image}
                             alt={item.altImageTxt ? item.altImageTxt : `carousel image ${i}`} itemProp='image'/>
                    </a>
                )
            }
        })
    }

    const renderRotator = () => {
        return itemList.items.map((item, i) => {
            if (i >= 3 && item.image) {
                const selected = i == selectedIndex
                return (
                    <a className={`carousel-img ${selected ? "" : "fade-hide"}`} key={i} href={item.url}
                       target={item.openInNewTab ? "_blank" : "_self"} itemProp='itemListElement'>
                        <img loading="lazy" className="multi-thumb" data-src={item.image}
                             alt={item.altImageTxt ? item.altImageTxt : `carousel image ${i}`} itemProp='image'/>
                    </a>
                )
            }
        })
    }

    return (
        <HCarouselHolder itemScope itemType='https://schema.org/ItemList'>
            {renderItems()}
            {renderRotator()}
        </HCarouselHolder>
    )
};

export default HCarousel;