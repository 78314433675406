import leftDecoration from "../../svg/left-decoration.svg";

type PropTypes = { showCPACTV: boolean, index: number }
const CpacTvLabel = (props: PropTypes) => {
    return props.showCPACTV && (
        <div className={`${props.index === 0 ? "featured-container__big-item__cpactv" : "featured-container__small-item__cpactv"}`}>
            <img src={leftDecoration} alt="left-decoration5"/>
            <span>CPAC TV</span>
        </div>
    )
};

export default CpacTvLabel;