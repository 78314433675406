import {useStore} from "../utils/hooks.ts";
import {DateTime} from "luxon";
import {useEffect, useState} from "react";

type PropTypes = {
    date: DateTime,
    refreshMillis?: number
}
/**
 * This component takes an input DateTime class
 * and renders a relative time string, for example,
 * "in 5 minutes", 'a few seconds ago", etc.
 * It selects the current locale.
 *
 * It refreshes the date at the interval specified,
 * in milliseconds. If no interval is specified, it
 * refreshes every second.
 */
const RelativeTime = ({date, refreshMillis = 1000}: PropTypes) => {
    const state = useStore();
    const localizedDate = date.setLocale(state.lang + "-CA").setZone(state.timezone);
    const [formatted, setFormatted] = useState(localizedDate.toRelative());
    useEffect(() => {
        const interval = setInterval(() => setFormatted(localizedDate.toRelative()), refreshMillis)
        return () => {
            clearInterval(interval)
        }
    }, []);
    return <span>{formatted}</span>;
};

export default RelativeTime;