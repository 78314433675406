import {Language} from "./language/Language.ts";

export type CpacStoreState = {
    livestream: Livestream | undefined
    lang: Language
    timezone: string
    localeStrings: Map<string, string>
}

const createStore = (initialState: CpacStoreState) => {
    let currentState: CpacStoreState = initialState
    const listeners = new Set<(callback: CpacStoreState) => void>()
    return {
        getState: () => {
            return currentState
        },
        subscribe: (listener: (callback: CpacStoreState) => void) => {
            listeners.add(listener);
            return () => listeners.delete(listener);
        },
        setLivestream: (value: Livestream) => {
            currentState = {...currentState, livestream: value};
            listeners.forEach((listener) => listener(currentState))
        },
        setLanguage: (value: Language) => {
            currentState = {...currentState, lang: value};
            listeners.forEach((listener) => listener(currentState))
        },
        setTimezone: (value: string) => {
            currentState = {...currentState, timezone: value};
            listeners.forEach((listener) => listener(currentState))
        },
        setLocaleStrings: (value: Map<string, string>) => {
            currentState = {...currentState, localeStrings: value};
            listeners.forEach((listener) => listener(currentState))
        }
    }
}

const store = createStore({
    livestream: undefined,
    lang: Language.en,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    localeStrings: new Map()
});

export default store;