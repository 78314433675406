const selectorsToResize: string[] = []
selectorsToResize.push('.live-container__item')
selectorsToResize.push('.featured-container__small-item')
selectorsToResize.push('.featured-container__small-item__info')
selectorsToResize.push('.video-thumb-container')
selectorsToResize.push('.carousel-img')
selectorsToResize.push('.hn-carousel-item')

export const resizeAll = () => {
    selectorsToResize.forEach(selector => resizeElement(selector))
}


export const resizeElement = (selector: string) => {
    document.querySelectorAll(selector).forEach((element) => {
        const elementWidth = element.clientWidth;
        const htmlEl = element as HTMLElement
        htmlEl.style.setProperty('--aspect-ratio-image-width', `${elementWidth}px`);
    });
}