import React, {useEffect, useState} from 'react';
import {normalizeForSearch} from "../utils/search-utils.ts";
import {useStore} from "../utils/hooks.ts";
import {Language} from "../language/Language.ts";
import ReactDOM from "react-dom/client";
import {getLabel} from "../language/lang-utils.ts";
import GetLabel from "../language/GetLabel.tsx";
import {ssg, sss} from "../utils/common-utils.ts";

type SearchRules = {
    provinceEn: string,
    provinceFr: string,
    children: [
        {
            channelEn: string,
            channelFr: string,
            BDU: string
        }
    ],
    community: string | null
}

export const renderFormInYourArea = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><InYourAreaSearch/></React.StrictMode>)
    }
}

export const InYourAreaSearch = () => {

    const store = useStore();
    const [searchRules, setSearchRules] = useState<SearchRules[]>();
    const [searchTerm, setSearchTerm] = useState<string | undefined>();
    const [searchResult, setSearchResult] = useState<SearchRules[]>();
    const [searchTermDefaultLoaded, setSearchTermDefaultLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!searchTermDefaultLoaded) {
            const community = ssg("community")
            if (community) {
                setSearchTerm(community as string);
                setSearchTermDefaultLoaded(true)
            }
        }
    }, [searchTermDefaultLoaded]);


    useEffect(() => {
        if (searchRules == null) {
            const div = document.getElementById("in-your-area-form");
            if (div != null) {
                const searchRulesResult = div.dataset["searchrules"]
                if (searchRulesResult) {
                    const searchRulesDecoded = atob(searchRulesResult)
                    if (searchRulesDecoded) {
                        setSearchRules(JSON.parse(searchRulesDecoded).response)
                    }
                }
            }
        }
    }, [searchRules]);

    useEffect(() => {
        if (searchRules && searchTermDefaultLoaded) {
            doSearch()
        }
    }, [searchRules, searchTermDefaultLoaded])

    const onSearchTermChange = (event: { target: { value: any; name: any; }; }) => {
        // @ts-ignore
        const {value, name} = event.target
        setSearchTerm(value);
        setSearchResult(undefined)
    }
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            doSearch()
        }
    }

    const doSearch = () => {
        if (searchRules && searchTerm) {
            sss("community", searchTerm)
            const filtered = searchRules.filter((rule) => {
                if (rule.community !== null) {
                    rule.community = normalizeForSearch(rule.community)
                }
                return rule.community === normalizeForSearch(searchTerm as string)
            });
            setSearchResult(filtered);
        }
    }

    const channelEnglish = (channel: string) => {
        return (
            <dl className="channel-en" tabIndex={0}>
                {channel &&
                    <dt className="inarea-text" tabIndex={0}><GetLabel name="ENGLISH"/><span className="inarea-channel">{channel}</span></dt>
                }
            </dl>
        );
    }

    const channelFrench = (channel: string) => {
        return (
            <dl className="channel-fr" tabIndex={0}>
                {channel &&
                    <dt className="inarea-text" tabIndex={0}><GetLabel name="FRENCH"/><span className="inarea-channel">{channel}</span></dt>
                }
            </dl>
        );
    }

    return (
        <div>
            <div className="inyour-area-bar">
                <input className="cpac-area-input" placeholder={getLabel("CITY")} aria-label={getLabel("CITY")} onChange={onSearchTermChange} onKeyPress={(e) => handleKeyDown(e)} value={searchTerm ? searchTerm : ""}/>
                <button className="btn-cityFind" onClick={() => {
                    doSearch()
                }}><GetLabel name="FIND"/></button>
            </div>

            <section className="inyour-area-search-results" tabIndex={0}>
                {searchResult && searchResult.map((rule) => {
                    return <div key={rule.provinceEn} itemScope itemType='https://schema.org/ItemList'>
                        <div className="inyour-area-community" tabIndex={0}>
                            <span className="community-label">{searchTerm && getLabel("RESULTS_FOR")}</span> <span className="community-name">{searchTerm && searchTerm !== '' ? searchTerm + ', ' : ''}{store.lang == Language.en ? rule.provinceEn : rule.provinceFr}</span>
                        </div>
                        {rule.children.map((child) => {

                            return <div className="company-item col" tabIndex={0} key={child.BDU} itemProp='itemListElement'>
                                <div>
                                    <h2 className="company-name" tabIndex={0}>{child.BDU}</h2>
                                    {store.lang == Language.en ? channelEnglish(child.channelEn) : channelFrench(child.channelFr)}
                                    {store.lang == Language.en ? channelFrench(child.channelFr) : channelEnglish(child.channelEn)}
                                </div>
                            </div>
                        })}
                    </div>
                })
                }
            </section>
        </div>
    )
}
