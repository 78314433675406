import {Duration} from "luxon";

export const config = {
    liveStreamReloadMillis: 15000,
    breakingNewsRotateMillis: 3500,
    defaultTimeZone: "America/Toronto",
    // youtubeApiKey: "AIzaSyCixEIerJo8_qmQ0Wd_R04IU4Vq8WI3iE0",
    youtubeApiKey: "AIzaSyAg4bmZK6I8DcUpge_dSU_eBmv9hmzk8tM",
    liveNotificationTime: Duration.fromObject({minutes: 15}),
    liveInFive: Duration.fromObject({minutes: 5}),
    liveInTwo: Duration.fromObject({minutes: 2}),
    contentModelCacheTtl: Duration.fromObject({seconds: 15}),
    contentModelFetchWaitTime: Duration.fromMillis(3500),
    jwPlayerScript: "https://cdn.jwplayer.com/libraries/BVcXD2FW.js",
    jwPlayerKey: "k+zSR9ehf17BxBK40Ixwic9zULNC6B1fNjr8dz43qh4=",
    recaptchaKey: "6LfAyL0lAAAAAFFjHex8ujj26MwQP-GU5Gp2rwvi",
    emailjsKey: "Q1PxlXCol3fY5VQpU",
    episodeInfoServiceUrl: "/api/1/services/episode-info.json",
    contentModelServiceUrl: "/api/1/services/contentModel.json"
}