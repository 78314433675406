import React, {useState} from 'react';
import {isEmail} from "../utils/common-utils.ts";
import ReactDOM from "react-dom/client";

export const renderFormContactFr = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><FormContactFr/></React.StrictMode>)
    }
}

const FormContactFr = () => {

    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [commentOn, setCommentOn] = useState('- Choisir -');
    const [commentDetails, setCommentDetails] = useState('');

    const renderTextField = (inputData: { name: any; id: any; label: any; type: any; value?: string; required: any; ariaRequired: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_text'>
                    <input name={inputData.name}
                           id={inputData.id}
                           type={inputData.type}
                           value={value}
                           className='medium'
                           aria-invalid="false"
                           aria-required={inputData.ariaRequired}
                           aria-describedby={inputData.description}
                           onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderTextArea = (inputData: { name: any; id: any; label: any; description: any; message: any; required: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_textarea'>
                <textarea name={inputData.name}
                          id={inputData.id}
                          className='textarea medium'
                          value={value}
                          aria-invalid="false" rows={10} cols={50}
                          aria-describedby={inputData.description}
                          onChange={(e) => onChange(e.target.value)}/>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }


    const renderPhoneField = (inputData: { name: any; id: any; label: any; type: any; required: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.id}
                        type={inputData.type}
                        value={value}
                        className='medium'
                        aria-required="true" aria-invalid="false"
                        aria-describedby={inputData.description}
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderEmailField = (inputData: { name: any; id: any; label: any; type?: string; required: any; description: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.id}
                        type='email'
                        value={value}
                        className='medium'
                        aria-required="true" aria-invalid="false" aria-describedby="field_description_email"
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                </div>
                <div className={`sp_field_description ${isEmail(value) ? "" : "invalid"}`}
                     id="field_description_email">{!isEmail(value) ? inputData.description : "Merci"}</div>
            </>
        );
    }


    const renderDropdown = (inputData: { name: any; id: any; label: any; option: any; required: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_select'>
                    <select
                        name={inputData.name}
                        id={inputData.id}
                        className='medium field_select'
                        aria-required="true"
                        aria-invalid="false"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        {inputData.option.map(({value, label}: any, i: any) => <option key={`${inputData.name}-${i}`}
                         value={value}>{label}</option>)}
                    </select>
                </div>
            </>
        )
    }

    const renderName = () => {
        const inputData = {
            name: 'name',
            id: 'nom',
            label: 'Nom',
            type: 'text',
            value: '',
            required: true,
            ariaRequired: 'true',
            description: 'field_description_name',
            message: 'Votre nom est obligatoire.'
        }
        return renderTextField(inputData, name, setName);
    }

    const renderCity = () => {
        const inputData = {
            name: 'city',
            id: 'location',
            label: 'Ville',
            type: 'text',
            value: '',
            required: true,
            ariaRequired: 'true',
            description: 'field_description_city',
            message: 'Votre emplacement est obligatoire.'
        }
        return renderTextField(inputData, city, setCity);
    }

    const renderEmailDetails = () => {
        const inputData = {
            name: 'email',
            id: 'emailAddress',
            label: 'Adresse courriel',
            type: 'email',
            required: true,
            description: 'Veuillez fournir votre adresse électronique',
        }
        return renderEmailField(inputData, emailAddress, setEmailAddress);
    }

    const renderPhoneDetails = () => {
        const inputData = {
            name: 'phone',
            id: 'phoneNumber',
            label: 'Téléphone',
            type: 'tel',
            required: false,
            description: 'field_description_phone',
            message: 'Votre numéro de téléphone est facultatif.'
        }
        return renderPhoneField(inputData, phone, setPhone);
    }

    const renderCommentChoices = () => {
        const inputData = {
            name: 'commentChoice',
            id: 'commentChoice',
            label: 'Sélectionner',
            option: [
                {label: '- Choisir -', value: 'Choisir'},
                {label: 'Général', value: 'Général'},
                {label: 'Site web', value: 'Site web'},
                {label: 'Vidéos archivées', value: 'Vidéos archivées'},
                {label: 'Émissions de CPAC', value: 'Émissions de CPAC'},
                {label: 'Carrières', value: 'Carrières'},
                {label: 'CPAC dans votre région', value: 'CPAC dans votre région'},
                {label: 'Commander une vidéo', value: 'Commander une vidéo'},
                {label: 'Médias sociales', value: 'Médias sociales'},
            ],
            required: true,
        }
        return renderDropdown(inputData, commentOn, setCommentOn);
    }

    const renderComments = () => {
        const inputData = {
            name: 'comments',
            id: 'comments',
            label: 'Commentaires',
            description: 'field_description_comments',
            message: 'Veuillez nous faire part de vos commentaires ou de vos questions.',
            required: true,
        }
        return renderTextArea(inputData, commentDetails, setCommentDetails);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const mailSubject = "?subject=Submission : Contactez-nous";
        const mailBody = `&body=Nom : ${name} %0D%0AVille : ${city}%0D%0AAdresse courriel : ${emailAddress}%0D%0ATéléphone : ${phone}%0D%0ACommenter sur : ${commentOn}%0D%0ACommentaires : ${commentDetails}` ;
        const sendMail = "mailto:comments@cpac.ca";
        window.open(sendMail + mailSubject + mailBody);
    }

    return (
        <>
            {
                <div id="form_wrapper" className="form_wrapper" itemScope itemType="https://schema.org/ContactPage">
                    <form onSubmit={(e) => handleSubmit(e)} itemScope itemType="https://schema.org/WebPageElement">
                        <div className="form_body" itemProp='mainEntityOfPage'>
                            {renderName()}
                            {renderCity()}
                            {renderEmailDetails()}
                            {renderPhoneDetails()}
                            {renderCommentChoices()}
                            {renderComments()}
                        </div>
                        <div className='form_footer left_label'>
                            <button type="submit" name='submit' className="btn--primary">Envoyer par courrier électronique</button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default FormContactFr;