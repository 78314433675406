import {httpGet} from "@craftercms/classes";
import {config} from "../../config.ts";

const KEY = config.youtubeApiKey

export const get = (path: string, params: any) => {

    const args = {
        part: 'snippet',
        maxResults: 50,
        key: KEY,
        ...params
    }

    return httpGet('https://www.googleapis.com/youtube/v3' + path, args)
}

