import {ItemListItemClass} from "./ItemListItemClass.ts";
import {getOptionalStringValue} from "../utils/common-utils.ts";
import {Language} from "../language/Language.ts";

export class ItemListClass {
    private constructor(
        readonly items: ItemListItemClass[],
        readonly viewmode: string,
        readonly paginated: boolean,
        readonly seeallbtn: boolean,
        readonly pageSize: number,
        readonly title?: string,
        readonly seeAllUrl?: string
    ) {
    }

    static constructInstance = (itemList: any, lang: Language) => {

        const items = ItemListClass.getItems(itemList, lang)
        const viewmode = ItemListClass.getViewMode(itemList)
        const paginated = ItemListClass.getPaginated(itemList);
        const seeallbtn = ItemListClass.getSeeAll(itemList)
        const title = getOptionalStringValue(itemList, "title", lang)
        const pagesize = ItemListClass.getPageSize(itemList)
        const seeAllUrl = getOptionalStringValue(itemList, "seeAllUrl", lang)
        return new ItemListClass(items, viewmode, paginated, seeallbtn, pagesize, title, seeAllUrl);
    }

    private static getSeeAll(obj: any): boolean {
        let val = obj["seeallbtn"]
        if (val && val.length > 0) return JSON.parse(val);
        val = obj["see_all_b"]
        if (val && val.length > 0) return JSON.parse(val);
        return false
    }

    private static getPaginated(obj: any): boolean {
        let val = obj["paginated"]
        if (val && val.length > 0) return JSON.parse(val);
        val = obj["paginated_b"]
        if (val && val.length > 0) return JSON.parse(val);
        return false
    }

    private static getViewMode(obj: any): string {
        let val = obj["view_mode_s"]
        if (val) return val;
        val = obj["viewmode"]
        if (val) return val;
        throw Error("No view mode set")
    }

    private static getPageSize(obj: any): number {
        let val = obj["page_size_t"]
        if (val) return parseInt(val);
        val = obj["pageSize"]
        if (val) return parseInt(val);
        val = obj["pagesize"]
        if (val) return parseInt(val);
        return 10;
    }

    private static getItems(obj: any, lang: Language): ItemListItemClass[] {
        const items = new Array<ItemListItemClass>();
        obj.item.map((it: any) => {
            const ili = ItemListItemClass.constructInstance(it, lang)
            if (ili && ili.url) { // filter out items without a URL (e.g. in the active language)
                items.push(ili);
            }
        })
        return items.filter(it => {
            return it.category != "IGNORE"
        });
    }
}