import styled from 'styled-components';

const HCarouselHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px 10px;

  a {
    display: inline-block;

    img {
      width: var(--aspect-ratio-image-width);
      height: calc((9 * var(--aspect-ratio-image-width)) / 16);
      object-fit: contain;
      transform: scale(0.95);
      opacity: 1.0;
      transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear, color 0.1s linear, opacity 0.1s linear, text-shadow 0.1s linear, transform 0.1s linear;

      &:hover {
        transform: scale(1);
        opacity: 1;
        transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear, color 0.1s linear, opacity 0.1s linear, text-shadow 0.1s linear, transform 0.1s linear;
      }
    }
  }

  span {
    a {
      width: 100%;
    }
  }

  .fade-hide {
    display: none;
    visibility: hidden;
  }

`;

export default HCarouselHolder;