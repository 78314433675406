export const toggleStaticAccordion: any = (panelToActivate: any) => {
    
    const buttons = panelToActivate.parentElement.querySelectorAll("button");
    const contents = panelToActivate.parentElement.querySelectorAll(".static-accordion-content");
    const links = panelToActivate.parentElement.querySelectorAll(".static-accordion-content p a.internal-link");
    
    buttons.forEach((button: HTMLButtonElement) => {
        button.setAttribute('aria-expanded', 'false');
    });

    contents.forEach((content: HTMLElement) => {
        content.setAttribute('aria-hidden', 'true');
    });

    links.forEach((link: HTMLAnchorElement) => {
        link.setAttribute('tabindex', '-1');
    })

    panelToActivate.querySelector('button').setAttribute('aria-expanded', 'true');
    panelToActivate.querySelector('.static-accordion-content').setAttribute('aria-hidden', 'false');
    panelToActivate.querySelector('.static-accordion-content p a.internal-link').setAttribute("tabindex", '0');

}