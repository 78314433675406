import {useEffect, useState} from 'react';
import {uniqueId} from 'lodash';
// @ts-ignore
import ReactJWPlayer from 'react-jw-player';
import Hls, {MediaPlaylist} from 'hls.js';
import store from "../../cpac-store.ts";
import {Language} from "../../language/Language.ts";
import {getLabelForLang} from "../../language/lang-utils.ts";
import {config} from "../../config.ts";
import {ReactJWPlayerPlaylist} from "../../types/ReactJWPlayerPlaylist.ts";

let evAudioList: any[] = []; // todo: clean this up

export type ReactJWPlayerContainerTypes = {
    setAudiosCB: any,
    vType: string,
    requestedVideoLang: string
    playlist: ReactJWPlayerPlaylist,
    btnClicked: boolean,
    audioClickedCB: any
    setShowCPACTV: any
    autoplay: boolean
}

export const ReactJWPlayerContainer = (props: ReactJWPlayerContainerTypes) => {
    const {setAudiosCB} = props
    const HLS = new Hls();
    Hls.DefaultConfig.enableWorker = false;
    const [playerID,] = useState<string>(uniqueId("pId-"))
    const [audioLoaded, setAudioLoaded] = useState<boolean>(false);
    const [audioTList, setAudioTList] = useState<MediaPlaylist[]>([]);
    const [preferredLang, setPreferredLang] = useState(props.requestedVideoLang)
    // const [audioMessage, setAudioMessage] = useState<string>();
	// let engAudio = false;
	// let freAudio = false;
    // let flrAudio = false;
    const isJwPlayerLoaded = () => {
        // @ts-ignore
        return window.jwplayer
    }
    const jwplayer = (playerID: string): ReactJWPlayer => {
        // @ts-ignore
        return window.jwplayer(playerID)
    }
    const playerReady = audioLoaded && isJwPlayerLoaded() && jwplayer(playerID) && jwplayer(playerID).getAudioTracks

    useEffect(() => {
        if (props.vType !== "video") {
            const reCheckAudio = setInterval(() => {
                readManifestAudios();
            }, 10000);

            return () => clearInterval(reCheckAudio);
        }
    });

    useEffect(() => {
        if (props && props.requestedVideoLang) {
            setPreferredLang(props.requestedVideoLang)
        }
    }, [props.requestedVideoLang])

    useEffect(() => {
        evAudioList = audioTList;
        setAudiosCB(audioTList);
    }, [audioTList, setAudiosCB]);

    const readManifestAudios = () => {
        if (Hls.isSupported() && props.playlist.file !== undefined) {
            HLS.loadSource(props.playlist.file);

            HLS.on(Hls.Events.MANIFEST_PARSED, function (_e, dt) {
                    const res = dt.audioTracks.filter(({lang: lang1}) => !evAudioList.some(({lang: lang2}) => lang2 === lang1));
                    if (evAudioList.length > 0 && res.length > 0) {
                        setAudioTList(dt.audioTracks);
                        setTimeout(() => {
                            window.location.reload();
                        }, 500);
                    }
                }
            )
        }
    }

    const setAudioTrackToPreferredLang = () => {
        const player = jwplayer(playerID);
        const curLang = preferredLang.toUpperCase();

        const flAudioTrack = audioTList.find(obj => {
            const curLang = obj.lang !== undefined ? obj.lang.toUpperCase() : obj.lang;
            return curLang === undefined || curLang === "FLOOR" || curLang === "MUL" || curLang === "UND";
        });
        const flTrackId = flAudioTrack === undefined ? undefined : flAudioTrack.id

        const frAudioTrack = audioTList.find(obj => {
            const curLang = obj.lang !== undefined ? obj.lang.toUpperCase() : obj.lang;
            return curLang === "FR";
        });

        const enAudioTrack = audioTList.find(obj => {
            const curLang = obj.lang !== undefined ? obj.lang.toUpperCase() : obj.lang;
            return curLang === "EN";
        });

        // engAudio = enAudioTrack !== undefined ? true : false;
        // freAudio = frAudioTrack !== undefined ? true : false;
        // flrAudio = flAudioTrack !== undefined ? true : false;

        // if (store.getState().lang === Language.en) {
        //     if (engAudio == false && flrAudio == false && freAudio == true) {
        //         setAudioMessage(getLabelForLang("FRE_AUDIO_ONLY", Language.en));
        //     } else if (freAudio == false && engAudio == false && flrAudio == false) {
        //         setAudioMessage(getLabelForLang("NO_AUDIO", Language.en));
        //     } 
        // } else if (store.getState().lang === Language.fr) {
        //     if (engAudio == true && flrAudio == false && freAudio == false) {
        //         setAudioMessage(getLabelForLang("ENG_AUDIO_ONLY", Language.fr));
        //     } else if (freAudio == false && engAudio == false && flrAudio == false) {
        //         setAudioMessage(getLabelForLang("NO_AUDIO", Language.fr));
        //     } 
        // } 

        switch (curLang) {
            case "EN":
                enAudioTrack !== undefined ? player.setCurrentAudioTrack(enAudioTrack.id) : player.setCurrentAudioTrack(flTrackId);
                break;
            case "FR":
                frAudioTrack !== undefined ? player.setCurrentAudioTrack(frAudioTrack.id) : player.setCurrentAudioTrack(flTrackId);
                break;
            case "FLOOR":
            case undefined:
                player.setCurrentAudioTrack(flTrackId);
                break;
            default:
                enAudioTrack !== undefined ? player.setCurrentAudioTrack(enAudioTrack.id) : player.setCurrentAudioTrack(flTrackId);
        }

        if (props.btnClicked) {
            player.play();
            props.audioClickedCB(false);
        }
    }

    useEffect(() => {
        if (playerReady) {
            setAudioTrackToPreferredLang()
        }
    }, [playerReady, preferredLang, playerID])

    const onResume = () => {
        if (props.setShowCPACTV) {
            props.setShowCPACTV(false)
        }
        if (playerID && audioLoaded) {
            setAudioTrackToPreferredLang();
        }
        fixAudioTracksButtonLabels()
    }

    const onPlay = () => {
        if (!audioLoaded) {
            onAudioTracksLoaded();
        }
        if (props.setShowCPACTV) {
            props.setShowCPACTV(false)
        }
        if (playerID && audioLoaded) {
            setAudioTrackToPreferredLang();
        }
        fixAudioTracksButtonLabels()
    }

    const onAudioTracksLoaded = () => {
        if (playerID) {
            if (Hls.isSupported() && props.playlist.file !== undefined) {
                HLS.loadSource(props.playlist.file);

                HLS.on(Hls.Events.MANIFEST_PARSED, function (_e, dt) {
                    setAudioTList(dt.audioTracks);
                    setAudioLoaded(true);
                });
            } else {
                const player = jwplayer(playerID);
                const audioTracks = player.getAudioTracks();

                const newAudioTracks = audioTracks.map((track: any, i: number) => {
                    return {...track, lang: track.language === "" ? "Floor" : track.language, id: i}
                });

                setAudioTList(newAudioTracks);

                if (newAudioTracks.length > 0) {
                    setAudioLoaded(true);
                    setAudioTrackToPreferredLang();
                }
            }
        }
    }

    const fixAudioTracksButtonLabels = () => {
        const english = () => {
            let label = undefined;
            if (store.getState().lang === Language.en) {
                label = getLabelForLang("ENGLISH", Language.en)
            } else if (store.getState().lang === Language.fr) {
                label = getLabelForLang("ENGLISH", Language.fr)
            }
            if (label === undefined) {
                label = "eng"
            }
            return label
        }
        const french = () => {
            let label = undefined;
            if (store.getState().lang === Language.en) {
                label = getLabelForLang("FRENCH", Language.en)
            } else if (store.getState().lang === Language.fr) {
                label = getLabelForLang("FRENCH", Language.fr)
            }
            if (label === undefined) {
                label = "fre"
            }
            return label
        }
        const multi = () => {
            let label = undefined;
            if (store.getState().lang === Language.en) {
                label = getLabelForLang("FLOOR", Language.en)
            } else if (store.getState().lang === Language.fr) {
                label = getLabelForLang("FLOOR", Language.fr)
            }
            if (label === undefined) {
                label = "mul"
            }
            return label
        }

        // per Vualto: "It’s a bit of a hack but, it’s a way to ensure that the tracks are always labelled correctly."
        const langBtns = document.querySelectorAll('.jw-settings-submenu-audioTracks .jw-settings-content-item')
        if (audioLoaded && langBtns && langBtns.length > 0) {
            langBtns.forEach(btn => {
                if (btn instanceof HTMLElement) {
                    // save this once - we will need it when we re-render
                    if (!btn.dataset.lang) {
                        switch (btn.innerHTML.toLowerCase()) {
                            case "english":
                                btn.dataset.lang = "eng";
                                break;
                            case "french":
                                btn.dataset.lang = "fre";
                                break;
                            case "floor":
                                btn.dataset.lang = "mul";
                                break;
                            default:
                                btn.dataset.lang = "mul" // usually the file name
                        }
                    }
                    let newLabel;
                    switch (btn.dataset.lang) {
                        case "eng":
                            newLabel = english();
                            break;
                        case "fre":
                            newLabel = french();
                            break;
                        case "mul":
                            newLabel = multi();
                            break;
                        default:
                            newLabel = btn.dataset.lang;
                    }

                    btn.ariaLabel = newLabel
                    btn.innerHTML = newLabel
                }
            })
        }
    }

    const renderPlayer = () => {
        if (playerID !== null && props.playlist.file !== null && config.jwPlayerKey && config.jwPlayerScript) {
            fixAudioTracksButtonLabels()
            return (
                <ReactJWPlayer
                    playlist={[props.playlist]}
                    licenseKey={config.jwPlayerKey}
                    playerId={playerID}
                    playerScript={config.jwPlayerScript}
                    onPlay={() => onPlay()}
                    onReady={() => onAudioTracksLoaded()}
                    onResume={() => {
                        onResume()
                        props.setShowCPACTV
                        props.setShowCPACTV(false)
                    }}
                    onPause={() => props.setShowCPACTV && props.setShowCPACTV(true)}
                    isAutoPlay={props.autoplay}
                    isMuted={false}
                    customProps={{
                        intl: {
                            en: {
                                errors: {
                                    badConnection: getLabelForLang("VIDEO_ERROR_BAD_CONNECTION", Language.en),
                                    cantLoadPlayer: getLabelForLang("VIDEO_ERROR_LOAD_FAILURE", Language.en),
                                    cantPlayInBrowser: getLabelForLang("VIDEO_ERROR_BROWSER", Language.en),
                                    cantPlayVideo: getLabelForLang("GENERIC_VIDEO_PLAYER_ERROR", Language.en),
                                    liveStreamDown: getLabelForLang("VIDEO_ERROR_STREAM_ENDED", Language.en),
                                    protectedContent: getLabelForLang("VIDEO_ERROR_PROTECTED", Language.en),
                                    technicalError: getLabelForLang("VIDEO_ERROR_TECHNICAL", Language.en),
                                },
                            },
                            fr: {
                                errors: {
                                    badConnection: getLabelForLang("VIDEO_ERROR_BAD_CONNECTION", Language.fr),
                                    cantLoadPlayer: getLabelForLang("VIDEO_ERROR_LOAD_FAILURE", Language.fr),
                                    cantPlayInBrowser: getLabelForLang("VIDEO_ERROR_BROWSER", Language.fr),
                                    cantPlayVideo: getLabelForLang("GENERIC_VIDEO_PLAYER_ERROR", Language.fr),
                                    liveStreamDown: getLabelForLang("VIDEO_ERROR_STREAM_ENDED", Language.fr),
                                    protectedContent: getLabelForLang("VIDEO_ERROR_PROTECTED", Language.fr),
                                    technicalError: getLabelForLang("VIDEO_ERROR_TECHNICAL", Language.fr),
                                },
                            },
                        },
                    }}
                />
            )
        }
    }

    return (
        <>
            {props.playlist && renderPlayer()}
            {/* {playerReady && audioMessage && <p className='audio_message'>{audioMessage}</p>} */}
        </>
    )

}