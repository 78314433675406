import React, {useState} from 'react';
import {isEmail} from "../utils/common-utils.ts";
import ReactDOM from "react-dom/client";

export const renderFormContactEn = (div: string) => {
    {
        const root = document.getElementById(div)
        if (root) {
            ReactDOM.createRoot(root).render(<React.StrictMode><FormContactEn/></React.StrictMode>)
        }
    }
}
const FormContactEn = () => {
    const [name, setName] = useState('');
    const [city, setCity] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [commentOn, setCommentOn] = useState('- Select -');
    const [commentDetails, setCommentDetails] = useState('');

    const renderTextField = (inputData: { name: any; id: any; label: any; type: any; value?: string; required: any; ariaRequired: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_text'>
                    <input name={inputData.name}
                           id={inputData.id}
                           type={inputData.type}
                           value={value}
                           className='medium'
                           aria-invalid="false"
                           aria-required={inputData.ariaRequired}
                           aria-describedby={inputData.description}
                           onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderTextArea = (inputData: { name: any; id: any; label: any; description: any; message: any; required: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_textarea'>
                    <textarea name={inputData.name}
                              id={inputData.id}
                              className='textarea medium'
                              value={value}
                              aria-invalid="false" rows={10} cols={50}
                              aria-describedby={inputData.description}
                              onChange={(e) => onChange(e.target.value)}/>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderPhoneField = (inputData: { name: any; label: any; id: any; type: any; required: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.id}
                        type={inputData.type}
                        value={value}
                        className='medium'
                        aria-required="true" aria-invalid="false"
                        aria-describedby={inputData.description}
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <p className='hidden' id={inputData.description}>{inputData.message}</p> : ""}
                </div>
            </>
        );
    }

    const renderEmailField = (inputData: { name: any; id: any; label: any; type?: string; required: any; description: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.id}
                        type='email'
                        value={value}
                        className='medium'
                        aria-required="true" aria-invalid="false" aria-describedby="field_description_email"
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                </div>
                <div className={`sp_field_description ${isEmail(value) ? "" : "invalid"}`}
                     id="field_description_email">{!isEmail(value) ? inputData.description : "Thank You"}</div>
            </>
        );
    }

    const renderDropdown = (
        inputData: { id: string; name: string; label: string; option: any; required: boolean; },
        value: string | number | readonly string[] | undefined,
        onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }
    ) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}{inputData.required ?
                    <span className='field_required'>*</span> : ""}</label>
                <div className='input_container input_container_select'>
                    <select
                        name={inputData.name}
                        id={inputData.id}
                        className='medium field_select'
                        aria-required="true"
                        aria-invalid="false"
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        {inputData.option.map(({value, label}: any, i: any) => <option key={`${inputData.name}-${i}`}
                         value={value}>{label}</option>)}
                    </select>
                </div>
            </>
        )
    }

    const renderName = () => {
        const inputData = {
            name: 'Your name',
            id: 'name',
            label: 'Name',
            type: 'text',
            value: '',
            required: true,
            ariaRequired: 'true',
            description: 'field_description_name',
            message: 'Your name is required.'
        }
        return renderTextField(inputData, name, setName);
    }

    const renderCity = () => {
        const inputData = {
            name: 'your city',
            id: 'location',
            label: 'Your City',
            type: 'text',
            value: '',
            required: true,
            ariaRequired: 'true',
            description: 'field_description_city',
            message: 'Your location is required.'
        }
        return renderTextField(inputData, city, setCity);
    }

    const renderEmailDetails = () => {
        const inputData = {
            name: 'your email',
            id: 'emailAddress',
            label: 'Email',
            type: 'email',
            required: true,
            description: 'Please provide a valid email address.',
        }
        return renderEmailField(inputData, emailAddress, setEmailAddress);
    }

    const renderPhoneDetails = () => {
        const inputData = {
            name: 'phone number',
            label: 'Phone',
            id: 'phoneNumber',
            type: 'tel',
            required: false,
            description: 'field_description_phone',
            message: 'Your phone number is optional.'
        }
        return renderPhoneField(inputData, phone, setPhone);
    }

    const renderCommentChoices = () => {
        const inputData = {
            id: 'commentChoice',
            name: 'Comment on',
            label: 'Comment on',
            option: [
                {label: '- Select -', value: 'Select'},
                {label: 'General', value: 'General'},
                {label: 'Website', value: 'Website'},
                {label: 'Video Archives', value: 'Video Archives'},
                {label: 'CPAC Programs', value: 'CPAC Programs'},
                {label: 'Careers', value: 'Careers'},
                {label: 'CPAC in Your Area', value: 'CPAC in Your Area'},
                {label: 'Order a Video', value: 'Order a Video'},
                {label: 'Social Media', value: 'Social Media'},
            ],
            required: true,
        };

        return renderDropdown(inputData, commentOn, setCommentOn);
    }

    const renderComments = () => {
        const inputData = {
            name: 'your comments',
            id: 'comments',
            label: 'Comments',
            description: 'field_description_comments',
            message: 'Please provide any comments or questions.',
            required: true,
        }
        return renderTextArea(inputData, commentDetails, setCommentDetails);
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const mailSubject = "?subject=Submission from Contact Form English";
        const mailBody = `&body=Name: ${name}%0D%0ACity: ${city}%0D%0AEmail Address: ${emailAddress}%0D%0APhone: ${phone}%0D%0ACommenting On: ${commentOn}%0D%0AComments: ${commentDetails}` ;
        const sendMail = "mailto:comments@cpac.ca";
        window.open(sendMail + mailSubject + mailBody);
    }

    return (
        <>
            {
                <div id="form_wrapper" className="form_wrapper" itemScope itemType="https://schema.org/ContactPage">
                    <form id="contact-form" onSubmit={(e) => handleSubmit(e)} itemScope itemType="https://schema.org/WebPageElement">
                        <div className="form_body" itemProp='mainEntityOfPage'>
                            {renderName()}
                            {renderCity()}
                            {renderEmailDetails()}
                            {renderPhoneDetails()}
                            {renderCommentChoices()}
                            {renderComments()}
                        </div>
                        <div className='form_footer left_label'>
                            <button type="submit" className="btn--primary">Send by Email</button>
                        </div>
                    </form>
                </div>
            }
        </>
    )
}

export default FormContactEn;