import {crafterConf, httpGet} from "@craftercms/classes";
import {composeUrl} from "@craftercms/utils";
import {config} from "../config.ts";

export const fetchEpisodeInfo = async (episodeId: string) => {
    const craftConf = crafterConf.getConfig();
    const serviceUrl = composeUrl(craftConf, config.episodeInfoServiceUrl);
    const params = {
        crafterSite: craftConf.site,
        id: episodeId
    }
    return httpGet(serviceUrl, params).toPromise()
        .then(data => {
            // console.info("Successful episode info fetch from CMS", data)
            return Promise.resolve(data)
        })
        .catch(error => {
            console.error("Failure fetching episode info from CMS", {episodeId, error})
            return Promise.reject(error)
        })
}

export const fetchContentModel = async (storeUrl: string, id: string | undefined) => {
    const craftConf = crafterConf.getConfig();
    const serviceUrl = composeUrl(craftConf, config.contentModelServiceUrl);
    const params = (id) ? {
        url: storeUrl,
        crafterSite: craftConf.site,
        id: id
    } : {
        url: storeUrl,
        crafterSite: craftConf.site,
    }
    return httpGet(serviceUrl, params).toPromise()
        .then(data => {
            // console.info("Successful fetch from CMS", storeUrl)
            return Promise.resolve(data)
        })
        .catch(error => {
            console.error("Failure fetching from CMS", {storeUrl, error})
            return Promise.reject(error)
        })
}