import {isToday} from "./util.ts";
import GetLabel from "../language/GetLabel.tsx";
import FormatDate from "./FormatDate.tsx";
import {DateTime} from "luxon";

const LiveCountdown = (props: {liveDateTime: DateTime}) => {
    return isToday(props.liveDateTime)
        ? <><GetLabel name={"TODAY"}/> <GetLabel name={"AT_WORD"}/> <FormatDate date={props.liveDateTime} format={"t"}/></>
        : <><FormatDate date={props.liveDateTime} format={"EEEE"}/> <GetLabel name={"AT_WORD"}/> <FormatDate date={props.liveDateTime} format={"t"}/></>
};

export default LiveCountdown;