export const truncateWords = (characters: string, length: number) => {
    if (length >= characters.length) return characters;

    let oldResult = /\s/.test(characters.charAt(length));
    // back up from the maxlength char until you find a space
    for (let i = length - 1; i > -1; i--) {
        const currentResult = /\s/.test(characters.charAt(i))
        if (oldResult && !currentResult) {
            return `${characters.substring(0, i + 1)}`;
        }
        oldResult = currentResult;
    }
    // if the string is "length" long with no spaces, we'll get here
    return '(...)';
}

export const search_submitForm = () => {
    const el = document.getElementById("currentSearchFormPageNumber")
    if (el && el instanceof HTMLInputElement) {
        el.value = "1";
    }
    document.querySelectorAll("form.search-form").forEach((e) => {
        if (e instanceof HTMLFormElement) {
            e.requestSubmit()
        }
    })
}
export const search_handleEnterKeyboardEvent = (e: Event) => {
    if (e instanceof KeyboardEvent) {
        if (e.key === 'Enter' || e.keyCode === 13) {
            search_submitForm();
        }
    }
}
export const search_truncateDescriptions = () => {
    const ul = document.getElementById("search-result-list-main")
    if (ul) {
        const kids = ul.children.length
        for (let i = 0; i < kids; i++) {

            const holder = document.getElementById("searchResultHolder-" + i)
            const textSpan = document.getElementById("searchResultDescription-" + i)
            if (holder && textSpan) {
                const orig = textSpan.innerText == null ? "" : textSpan.innerText
                const text = truncateWords(orig, 225)
                textSpan.innerText = text
                if (orig.length > text.length) {
                    const ellipsisSpan = document.createElement("span")
                    ellipsisSpan.setAttribute("class", "description__ellipsis")
                    ellipsisSpan.innerText = "(...)"
                    holder.append(ellipsisSpan)
                }
            }
        }
    }
}

export const search_setPage = (newPage: number) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set("page", newPage.toString());
    history.replaceState(null, "", "?" + queryParams.toString());
    location.reload()
    window.scrollTo(0, 350);
}

export const search_setProgramId = () => { 
    const queryParams = new URLSearchParams(window.location.search);
    const el = document.getElementById("currentSearchProgramId")
    let programID = "";
    if (el && el instanceof HTMLInputElement) {
        programID = el.value;
    }
    if(programID != ""){
        queryParams.set("programId", programID.toString());
    }
    history.replaceState(null, "", "?" + queryParams.toString());
    location.reload();
}

export const search_setTagId = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const el = document.getElementById("currentSearchTagId")
    let tagID = "";
    if (el && el instanceof HTMLInputElement) {
        tagID = el.value;
    }
    if(tagID != ""){
        queryParams.set("tagId", tagID.toString());
    }
    history.replaceState(null, "", "?" + queryParams.toString());
    location.reload();
}

export const normalizeForSearch = (text: string) => {
    return text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
}