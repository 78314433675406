import styled from 'styled-components';

const HnCarouselHolder = styled.div`
  .hn__content {
    margin: 0 15px;

    a {
      text-decoration: none;
      font-family: 'Quicksand', sans-serif;
    }

    ul {
      list-style: none;
      padding: 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-rows: auto;
      gap: 25px;
      margin: 0 auto;
      max-width: calc(100% - 50px);
      /* Landscape iPad */
      @media screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
        grid-template-columns: repeat(2, 1fr);
      }
      /* Portrait iPad Pro */
      @media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 920px) {
        grid-template-columns: repeat(2, 1fr);
      }
      /* iPhone SE */
      @media screen and (max-width: 548px) {
        grid-template-columns: 1fr;
      }

      li {
        background-color: #fdfdfc;
        margin-bottom: 12px;
        position: relative;

        h2 {
          font-size: 17px;
          text-transform: uppercase;
          font-weight: 700;
          color: #5f7586;
          margin: 0 0 5px 0 !important;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 17px;
          color: #383838;
          line-height: 1.6em;
        }

        .header__container {
          position: relative;
          //padding-top: calc((9 * var(--aspect-ratio-image-width)) / 16);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .description__container {
          padding: 8px 20px 8px 20px;
        }
      }
    }
  }
`;

export default HnCarouselHolder;