import React, {useState, useRef} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';
import {config} from '../config.ts'
import ReactDOM from "react-dom/client";

export const renderFormFeedbackEn = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><FormFeedbackEn/></React.StrictMode>)
    }
}
const FormFeedbackEn = () => {
    const [name, setName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [issueType, setIssueType] = useState('- Select -');
    const [otherIssue, setOtherIssue] = useState('');
    const [subject, setSubject] = useState('');
    const [feedback, setFeedback] = useState('');
    const [messageSent, setMessageSent] = useState(false);
    const [messageError, setMessageError] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const captchaKey = config.recaptchaKey;

    const renderTextField = (inputData: { name: any; id?: string; label: any; type: any; value?: string; required?: string | boolean; ariaRequired: any; description: any; message?: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.name}>{inputData.label}</label>
                <div className='input_container input_container_text'>
                    <input name={inputData.name}
                           id={inputData.name}
                           type={inputData.type}
                           value={value}
                           className='medium'
                           aria-invalid="false"
                           aria-required={inputData.ariaRequired}
                           aria-describedby={inputData.description}
                           onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <span id={inputData.description}>{inputData.message}</span> : ""}
                </div>
            </>
        );
    }

    const renderTextArea = (inputData: { name: any; label: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.name}>{inputData.label}<span
                    className='field_required'>*</span></label>
                <div className='input_container input_container_textarea'>
                    <textarea name={inputData.name}
                              id={inputData.name}
                              className='textarea medium'
                              value={value}
                              aria-invalid="false" rows={10} cols={50}
                              aria-describedby={inputData.description}
                              onChange={(e) => onChange(e.target.value)}/>
                    {inputData.message ? <span id={inputData.description}>{inputData.message}</span> : ""}
                </div>
            </>
        );
    }

    const renderEmailField = (inputData: { name: any; label: any; type?: string; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.name}>{inputData.label}</label>
                <div className='input_container input_container_email'>
                    <input
                        name={inputData.name}
                        id={inputData.name}
                        type='email'
                        value={value}
                        className='medium'
                        aria-required="false" aria-invalid="false" aria-describedby={inputData.description}
                        onChange={(e) => onChange(e.target.value)}>
                    </input>
                    {inputData.message ? <span id={inputData.description}>{inputData.message}</span> : ""}
                </div>
            </>
        );
    }

    const renderDropdown = (inputData: { name: any; id: any; label: any; option: any; description: any; message: any; }, value: string | number | readonly string[] | undefined, onChange: { (value: React.SetStateAction<string>): void; (arg0: string): void; }) => {
        return (
            <>
                <label className='field_label' htmlFor={inputData.id}>{inputData.label}<span
                    className='field_required'>*</span></label>
                <div className='input_container input_container_select'>
                    <select
                        name={inputData.name}
                        id={inputData.id}
                        className='medium field_select'
                        aria-required="true"
                        aria-invalid="false"
                        aria-describedby={inputData.description}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                    >
                        {inputData.option.map(({value, label}: any, i: any) => <option key={`${inputData.name}-${i}`}
                                                                                       value={value}>{label}</option>)}
                    </select>
                    {inputData.message ? <span id={inputData.description}>{inputData.message}</span> : ""}
                </div>
            </>
        )
    }

    const renderName = () => {
        const inputData = {
            name: 'Your name',
            id: 'name',
            label: 'Name (optional)',
            type: 'text',
            value: '',
            required: false,
            ariaRequired: 'false',
            description: 'field_description_name',
            message: 'If you wish to provide feedback anonymously, please leave the name field blank.'
        }
        return renderTextField(inputData, name, setName);
    }

    const renderEmailDetails = () => {
        const inputData = {
            name: 'email',
            label: 'Email (optional)',
            type: 'email',
            description: 'field_description_email',
            message: 'If you wish to provide feedback anonymously, please leave the email field blank.'
        }
        return renderEmailField(inputData, emailAddress, setEmailAddress);
    }

    const renderPhone = () => {
        const inputData = {
            name: 'phone',
            label: 'Telephone number (optional)',
            type: 'text',
            value: '',
            ariaRequired: 'false',
            description: 'field_description_phone',
            message: 'If you wish to provide feedback anonymously, please leave the telephone number field blank.'
        }
        return renderTextField(inputData, phone, setPhone);
    }

    const renderIssueType = () => {
        const inputData = {
            name: 'issue-type',
            id: 'issue-type',
            label: 'Issue Type',
            option: [
                {label: '- Select -', value: '- Select -'},
                {label: 'Content Accessibility', value: 'Content Accessibility'},
                {label: 'Digital Accessibility (website/app)', value: 'Digital Accessibility (website/app)'},
                {label: 'Employment', value: 'Employment'},
                {label: 'CPAC facilities', value: 'CPAC facilities'},
                {label: 'Request an alternate format', value: 'Request an alternate format'},
                {label: 'Other (please specify)', value: 'Other'},
            ],
            description: 'field_description_issue',
            message: 'Please select the option that best relates to your concern.'
        };

        return renderDropdown(inputData, issueType, setIssueType);
    }

    const renderOtherIssue = () => {
        const inputData = {
            name: 'other-issue',
            id: 'otherIssue',
            label: 'Issue Type - Other',
            type: 'text',
            value: '',
            required: '',
            ariaRequired: 'false',
            description: 'field_description_other_issue',
        }
        return renderTextField(inputData, otherIssue, setOtherIssue);
    }

    const renderSubject = () => {
        const inputData = {
            name: 'subject',
            label: 'Subject',
            type: 'text',
            value: '',
            ariaRequired: 'false',
            description: 'field_description_subject',
            message: 'Please summarize your concern in less than 60 characters.'
        }
        return renderTextField(inputData, subject, setSubject);
    }

    const renderFeedback = () => {
        const inputData = {
            name: 'feedback',
            label: 'Feedback',
            description: 'field_description_feedback',
            message: 'Please describe your concerns, including any relevant details such as the webpage or program involved. Feedback can be general.'
        }
        return renderTextArea(inputData, feedback, setFeedback);
    }
    const captchaRef = useRef(null);

    const onReCaptchaChange = (value: any) => {
        setCaptchaValue(value);
    }

    const buttonDisabled = (captchaValue: any) => {
        return (
            !captchaValue
        )
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();

        // captchaRef.current.reset();

        const templateParams = {
            senderLanguage: "English",
            senderName: name ? name : "Anonymous",
            senderEmail: emailAddress ? emailAddress : "email address was not provided",
            senderPhone: phone ? phone : "phone number was not provided",
            senderIssueType: issueType === "Other" ? otherIssue : issueType !== "Other" ? issueType : "an issue was not provided",
            senderSubject: subject ? subject : "no subject provided",
            senderComments: feedback
        };
        emailjs.send('outlook_service', 'feedback_template', templateParams, config.emailjsKey)
            .then(function () {
                setMessageSent(true);
                e.target.reset(); // reset the form
            }, function () {
                setMessageError(true);
            });
    }

    return (
        <>
            {
                messageSent ?
                    <h2 className='form_wrapper message_success'>Thank you for your submission.</h2> : messageError ?
                        <h2 className='form_wrapper message_fail'>Sorry there was a problem with your submission. Please try
                            again later.</h2> :
                        <div id="form_wrapper" className="form_wrapper">
                            <form id="contact-form" onSubmit={(e) => handleSubmit(e)} itemScope
                                  itemType="https://schema.org/WebPageElement">
                                <div className="form_body" itemProp='mainEntityOfPage'>
                                    {renderName()}
                                    {renderEmailDetails()}
                                    {renderPhone()}
                                    {renderIssueType()}
                                    {issueType === 'Other' ? renderOtherIssue() : ''}
                                    {renderSubject()}
                                    {renderFeedback()}
                                </div>
                                <div className='recaptcha'>
                                    <ReCAPTCHA sitekey={captchaKey} ref={captchaRef} onChange={onReCaptchaChange}/>
                                </div>
                                <div className='form_footer left_label'>
                                    <button type="submit" name='submit' value='submit' className="btn--primary"
                                            disabled={buttonDisabled(captchaValue)}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
            }
        </>
    )
}

export default FormFeedbackEn;