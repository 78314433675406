export const shows_showAndHide = (showSelector: string, hideSelector: string) => {
    document.querySelectorAll(showSelector).forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.classList.contains("modeHidden")) {
                e.classList.remove("modeHidden")
            }
        }
    })
    document.querySelectorAll(hideSelector).forEach(e => {
        if (e instanceof HTMLElement) {
            if (!e.classList.contains("modeHidden")) {
                e.classList.add("modeHidden")
            }
        }
    })
}