import {useEffect, useRef} from 'react';

type PropTypes = {
    video: any,
    selectedVideo: any,
    selected: any
    className: string
}
const YTVideoDetail = (props: PropTypes) => {
    const {video, selectedVideo, selected} = props;
    const videoContainer = useRef(null);

    useEffect(() => {
        if (selectedVideo && videoContainer && videoContainer.current != null) {
            // @ts-ignore todo: investigate why typescript does not like this
            videoContainer.current.focus();
        }
    }, [selectedVideo]);

    if (!video) {
        return <div></div>;
    } else {
        const videoSrc = `https://www.youtube.com/embed/${video.snippet.resourceId.videoId}`;

        return (
            <div className={`yt-video-detail-holder ${selected ? "show" : "hide"}`}>
                <div className="yt-video-embed">
                    <iframe ref={videoContainer} title="yt-video-player" src={videoSrc}/>
                </div>
            </div>
        )
    }
}

export default YTVideoDetail;