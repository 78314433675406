import {useStore} from "../utils/hooks.ts";
import {DateTime} from "luxon";

type PropTypes = {
    date: DateTime,
    format: string
}
/**
 * This component takes an input DateTime class,
 * changes its locale to the local of the current
 * user, and changes the timezone to the timezone
 * of the current use (both as stored in the CpacStore),
 * and renders it in the specified format.
 *
 */
const FormatDate = (props: PropTypes) => {
    const state = useStore();
    const localizedDate = props.date.setLocale(state.lang + "-CA").setZone(state.timezone);
    const formatted = localizedDate.toFormat(props.format)
    return <span>{formatted}</span>;
};

export default FormatDate;