import React, {useEffect, useState} from 'react';
import {getItem} from '@craftercms/content';
import {useStore} from "../utils/hooks.ts";
import {Language} from "../language/Language.ts";
import {DateTime} from "luxon";
import {FaXmark} from "../icons/faXmark.tsx";
import ReactDOM from "react-dom/client";

export const renderCookieBar = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><CookieBar/></React.StrictMode>)
    }
}
export const CookieBar = () => {

    const store = useStore();

    const COOKIE_NAME_YES = "cpac_2024_mcookie_yes"
    const COOKIE_NAME_NO = "cpac_2024_mcookie_no"

    const [cookieChecked, setCookieChecked] = useState(false);
    const [accept, setAccept] = useState<boolean | undefined>()

    const [message, setMessage] = useState<string>();

    useEffect(() => {
        if (accept === undefined) {
            if (document.cookie.indexOf(COOKIE_NAME_YES) !== -1) {
                setAccept(true)
            } else if (document.cookie.indexOf(COOKIE_NAME_NO) !== -1) {
                setAccept(false)
            }
            setCookieChecked(true)
        }
    }, []);

    const isQuestionAnswered = () => {
        // don't care what the answer is, only that we have one!
        return !(accept === undefined)
    }

    useEffect(() => {
        // check the cookie before doing anything else
        if (cookieChecked) {
            if (!isQuestionAnswered()) {
                // not answered - load the question
                getItem("/site/components/common/cookielawbar.xml").subscribe((item) => {
                    if (item) {
                        if (store.lang == Language.en) {
                            setMessage(item.descriptorDom.component.message_en_html)
                        } else {
                            setMessage(item.descriptorDom.component.message_fr_html)
                        }
                    }
                });
            }
        }
    }, [cookieChecked, accept, store]);

    if (!message) {
        return null
    }

    if (isQuestionAnswered()) {
        return null;
    }

    const setCookieLaw = (accepted: boolean) => {
        const name = accepted ? COOKIE_NAME_YES : COOKIE_NAME_NO
        let date = DateTime.now()
        date = date.plus({days: 90})
        document.cookie = name + "=mc; expires=" + date.toISO() + "; path=/";
        setAccept(accepted);
    }

    const cookieYesButtonHandler = () => {
        setCookieLaw(true);
    }

    const cookieNoButtonHandler = (e: any) => {
        if (e != null && e.target != null) {
            // @ts-ignore
            if (e.target.id === "cookie_reject") {
                setCookieLaw(false);
            }
        }
    }


    // @ts-ignore
    return (
        <div className="cpac-cookie-law-bar">
            <div className="cpac-cookies-message" dangerouslySetInnerHTML={{__html: message}} onClick={cookieNoButtonHandler}/>
            <div className="cpac-cookies-buttons">
                <a className="cpac-cookies-yes-button" onClick={cookieYesButtonHandler}>
                    <FaXmark/>
                </a>
            </div>
        </div>
    )
}
