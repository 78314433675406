import {useEffect, useState} from 'react';

import {get} from "./api.ts";
import YTVideoList from "./YTVideoList.tsx";

type PropTypes = {
    playlistId: string,
    title: string,
    selected: any,
    setSelected: any
}

const YTVideo = (props: PropTypes) => {
    const {playlistId, title, selected, setSelected} = props;
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);

    useEffect(() => {
        get('/playlistItems', {playlistId: playlistId}).subscribe(videoRes => {
            setVideos(videoRes.items)
        })
    }, [playlistId]);

    return (
        <>
            {videos &&
                <div className="yt-list-container">
                    <YTVideoList selected={selected}
                                 playlistId={playlistId}
                                 onVideoSelect={(video: any) => {
                                     setSelectedVideo(video);
                                     setSelected(playlistId);
                                 }}
                                 videos={videos}
                                 selectedVideo={selectedVideo}
                                 title={title}/>
                </div>
            }
        </>
    )
}

export default YTVideo;