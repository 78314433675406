import {header_hambClickHandler, header_handleClickOutside, header_handleEnterPress, header_handleMenuClick, header_hideAll, header_liveBarClickHandler, header_rotateBreakingNews, header_searchClickHandler, header_update, loadLiveStreams} from "./header-utils.ts";
import {debounce} from "lodash";
import {search_handleEnterKeyboardEvent, search_setPage, search_setProgramId, search_setTagId, search_submitForm, search_truncateDescriptions} from "./search-utils.ts";
import {share_onFaClickHandler, share_onTwClickHandler, share_onLiClickHandler} from "./share-utils.ts";
import {schedule_formatTimes, schedule_setSelectedTimeZone, schedule_toggleAccordion, schedule_updateAccordion} from "./schedule-utils.ts";
import { article_toggleAccordion, article_updateAccordion } from "./article-utils.ts";
import {landingpage_toggleAccordion} from "./landingpage-utils.ts";
import {shows_showAndHide} from "./shows-utils.ts";
import {loadAppropriateImages} from "./image-utils.ts";
import {resizeAll} from "./responsive.ts";
import {config} from "../config.ts";
import { toggleStaticAccordion } from "./static-utils.ts";

/*
 * CpacEventListeners.initializeEventListeners()
 *
 * This function sets up event listeners that need to be run on interactive components on the site
 * that are NOT react components.  The majority of the elements referenced here are rendered in
 * Freemarker code, server-side.  This function is called as soon as the JavaScript loads.
 */

export const initializeEventListeners = () => {

    // header on-load action
    document.addEventListener('click', header_handleClickOutside, true);
    header_hideAll()
    header_update()
    loadLiveStreams()
    setInterval(loadLiveStreams, config.liveStreamReloadMillis);
    setInterval(header_rotateBreakingNews, config.breakingNewsRotateMillis);

    const headerHambIcon = document.getElementById("hambClickHandler")
    if (headerHambIcon) {
        headerHambIcon.addEventListener('click', header_hambClickHandler)
        headerHambIcon.addEventListener('keydown', e => header_handleEnterPress(e, header_handleMenuClick))
    }

    const liveBarClickHandler = document.getElementById("liveBarClickHandler");
    if (liveBarClickHandler) {
        liveBarClickHandler.addEventListener('click', header_liveBarClickHandler)
        liveBarClickHandler.addEventListener('keydown', e => header_handleEnterPress(e, header_liveBarClickHandler))
    }

    const searchClickHandler = document.getElementById("searchClickHandler");
    if (searchClickHandler) {
        searchClickHandler.addEventListener('click', header_searchClickHandler)
        searchClickHandler.addEventListener('keydown', e => header_handleEnterPress(e, header_searchClickHandler))
    }
    document.querySelectorAll("a.side-menu-link").forEach(e => {
        e.addEventListener('click', header_hideAll)
    })

    // language toggle
    document.querySelectorAll("#languageClickHandler").forEach(node => {
        node.addEventListener("click", ev => setLanguagePreference(ev))
        node.addEventListener("keydown", ev => setLanguagePreference(ev))
    })

    const setLanguagePreference = (event: Event) => {
        if (event.target && event.target instanceof HTMLElement && event.target.dataset) {
            const data = event.target.dataset
            if (data.lan && data.url) {
                localStorage.setItem("cpacPreferredLang", data.lan)
                window.location.href = data.url
            }
        }
    }

    // all special resize handling
    resizeAll();
    window.addEventListener('resize', debounce(function () {
        resizeAll()
    }, 300));


    // search
    if (document.getElementById("search-holder")) {
        search_truncateDescriptions()
    }
    document.querySelectorAll("div.search-box input.search-field").forEach(e => {
        e.addEventListener('keydown', ev => search_handleEnterKeyboardEvent(ev))
    })
    document.querySelectorAll("div.not-found-404-holder div.search-container input").forEach(e => {
        e.addEventListener('keydown', ev => search_handleEnterKeyboardEvent(ev))
    })
    document.querySelectorAll("div.simple-search-bar-holder input").forEach(e => {
        e.addEventListener('keydown', ev => search_handleEnterKeyboardEvent(ev))
    })
    document.querySelectorAll("#pagination__control_prev").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.pagenum) {
                const num = parseInt(e.dataset.pagenum);
                e.addEventListener('click', () => search_setPage(num));
            }
        }
    })
    document.querySelectorAll("#pagination__control_next").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.pagenum) {
                const num = parseInt(e.dataset.pagenum);
                e.addEventListener('click', () => search_setPage(num));
            }
        }
    })
    document.querySelectorAll(".search-filters .custom-select select").forEach((e) => {
        e.addEventListener('change', () => search_setProgramId())
        e.addEventListener('change', () => search_setTagId())
        e.addEventListener('change', () => search_submitForm())
    })
    document.querySelectorAll(".search-form .search-box .search-submit").forEach((e) => {
        e.addEventListener('click', () => search_submitForm())
        e.addEventListener('keydown', (ev) => search_handleEnterKeyboardEvent(ev))
    })


    // search bar
    document.querySelectorAll("#search-bar .container input").forEach(e => {
        e.addEventListener('change', ev => search_handleEnterKeyboardEvent(ev)) // todo: this will be a noop
        e.addEventListener('keydown', ev => search_handleEnterKeyboardEvent(ev))
    })


    // share
    document.querySelectorAll("div.share-container button.faBtn").forEach(e => {
        e.addEventListener('click', share_onFaClickHandler)
    })
    document.querySelectorAll("div.share-container button.twBtn").forEach(e => {
        e.addEventListener('click', share_onTwClickHandler)
    })
    document.querySelectorAll("div.share-container button.liBtn").forEach(e => {
        e.addEventListener('click', share_onLiClickHandler)
    })

    // schedule
    schedule_formatTimes()
    document.querySelectorAll("#js-timezone-picker button").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.tzcode) {
                const code = e.dataset.tzcode
                e.addEventListener('click', () => schedule_setSelectedTimeZone(code));
            }
        }
    })
    document.querySelectorAll("div.schedule-list-wrapper div.accordion").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.index) {
                const index = parseInt(e.dataset.index);
                e.addEventListener('click', () => schedule_toggleAccordion(index));
                // todo: this should only use specific key events, but now (and pre-freemarker) it listened for all
                e.addEventListener('keydown', () => schedule_toggleAccordion(index));
            }
        }
    })
    document.querySelectorAll(".schedule-page-holder").forEach(() => {
        window.onload = function () {
            schedule_updateAccordion()
        }
    })

    // article page  
    document.querySelectorAll("div.article-list-wrapper div.accordion").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.index) {
                const index = parseInt(e.dataset.index);
                e.addEventListener('click', () => article_toggleAccordion(index));
                // todo: this should only use specific key events, but now (and pre-freemarker) it listened for all
                e.addEventListener('keydown', () => article_toggleAccordion(index));
            }
        }
    })
    document.querySelectorAll(".article-page-holder").forEach(() => {
        window.onload = function () {
            article_updateAccordion()
        }
    })

    // landing page
    document.querySelectorAll("#landing-page-holder .lp-aboutLinks button").forEach(e => {
        if (e instanceof HTMLElement) {
            e.addEventListener('click', (event) => landingpage_toggleAccordion(event));
        }
    })


    // shows page
    document.querySelectorAll("#shows-page-holder #shows-page-grid-toggle").forEach(e => {
        if (e instanceof HTMLElement) {
            e.addEventListener('click', () => shows_showAndHide("#shows-page-grid-view", "#shows-page-list-view"));
        }
    })
    document.querySelectorAll("#shows-page-holder #shows-page-list-toggle").forEach(e => {
        if (e instanceof HTMLElement) {
            e.addEventListener('click', () => shows_showAndHide("#shows-page-list-view", "#shows-page-grid-view"));
        }
    })

    // images
    document.addEventListener("DOMContentLoaded", loadAppropriateImages);
    document.addEventListener("scroll", loadAppropriateImages);
    window.addEventListener("resize", loadAppropriateImages);
    window.addEventListener("orientationChange", loadAppropriateImages);
    loadAppropriateImages()

    // static page accordions
    const staticAccordion: HTMLElement | null = document.querySelector(".static-accordion");
    staticAccordion?.addEventListener('click', (e: Event) => {
        const activePanel: HTMLElement | null = (e.target as HTMLElement).closest(".static-accordion-panel");
        if (!activePanel) return;
        toggleStaticAccordion(activePanel);
    });
    
}