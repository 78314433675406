import {getOptionalStringValue} from "../utils/common-utils.ts";
import {Language} from "../language/Language.ts";
import {DateTime} from "luxon";
import {parseDate} from "../date/util.ts";

export class ItemListItemClass {
    constructor(readonly altImageTxt: string | undefined,
                readonly category: string | undefined,
                readonly categoryURL: string | undefined,
                readonly image: string | undefined,
                readonly openInNewTab: boolean,
                readonly title: string | undefined,
                readonly url: string | undefined,
                readonly description: string | undefined,
                readonly episodeId: string | undefined,
                readonly programId: number | undefined,
                readonly type: string | undefined,
                readonly videoUrl: string | undefined,
                readonly videoDuration: string | undefined,
                readonly liveDateTime: DateTime,
                readonly lastDateModified: DateTime,
    ) {
    }

    static constructInstanceFromItemList(itemList: ItemList, language: Language) {
        let altImageTxt;
        let categoryURL;
        let category;
        let description;
        let image;
        let title;
        let url;

        if (language == Language.fr) {
            altImageTxt = itemList.altImageTxt_fr_s
            categoryURL = itemList.categoryURL_fr_s
            category = itemList.category_fr_t
            description = itemList.description_fr_t
            image = itemList.image_fr_s
            title = itemList.title_fr_t
            url = itemList.url_fr_s
        } else {
            altImageTxt = itemList.altImageTxt_en_s
            categoryURL = itemList.categoryURL_en_s
            category = itemList.category_en_t
            description = itemList.description_en_t
            image = itemList.image_en_s
            title = itemList.title_en_t
            url = itemList.url_en_s
        }
        let episodeId = itemList.episodeId
        let lastDateModified = parseDate(itemList.lastDateModified) ?? DateTime.now()
        let liveDateTime = parseDate(itemList.liveDateTime)!
        let openInNewTab = itemList.openInNewTab
        let program_id = itemList.program_id
        let type = itemList.type
        let videoDuration = itemList.videoDuration
        let videoURL = itemList.videoURL

        return new ItemListItemClass(
            altImageTxt,
            category,
            categoryURL,
            image,
            openInNewTab,
            title,
            url,
            description,
            episodeId,
            program_id,
            type,
            videoURL,
            videoDuration,
            liveDateTime,
            lastDateModified
        )
    }

    static constructInstance(it: any, lang: Language): ItemListItemClass | undefined {
        const altImageTxt = getOptionalStringValue(it, "altImageTxt", lang);
        const category = getOptionalStringValue(it, "category", lang)
        const categoryURL = getOptionalStringValue(it, "categoryURL", lang);
        const image = getOptionalStringValue(it, "image", lang);
        const openInNewTab = this.getOpenInNewTab(it);
        const title = getOptionalStringValue(it, "title", lang);
        const url = getOptionalStringValue(it, "url", lang);

        const description = getOptionalStringValue(it, "description", lang);
        const episodeId = getOptionalStringValue(it, "episodeId", lang);
        const programId = it.program_id;
        const type = getOptionalStringValue(it, "type", lang);
        const videoUrl = getOptionalStringValue(it, "videoUrl", lang);
        const videoDuration = getOptionalStringValue(it, "videoDuration", lang);
        const liveDateTime: DateTime = parseDate(it.liveDateTime)!
        const lastDateModified: DateTime = parseDate(it.lastDateModified) ?? DateTime.now()

        return new ItemListItemClass(altImageTxt, category, categoryURL, image,
            openInNewTab, title, url, description, episodeId, programId, type, videoUrl,
            videoDuration, liveDateTime, lastDateModified);
    }


    private static getOpenInNewTab(obj: any): boolean {
        let val = obj["openInNewTab"]
        if (val && val.length > 0) return JSON.parse(val);
        val = obj["open_new_tab_b"]
        if (val && val.length > 0) return JSON.parse(val);
        return false
    }
}