import {Language} from "../language/Language.ts";

export const isEmail = (email: string | number | readonly string[] | undefined) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(email as string);
}

export const ssg = (name: string) => {
    const val = sessionStorage.getItem(name)
    if (val === "true") return true
    else if (val === "false") return false
    else return val
}
export const sss = (name: string, value: string | boolean) => {
    if (typeof value === "string") {
        sessionStorage.setItem(name, value)
    } else {
        sessionStorage.setItem(name, value ? "true" : "false")
    }
}

export const addClass = (element: HTMLElement, className: string) => {
    if (element && className) {
        element.classList.add(className)
    }
}

export const removeClass = (element: HTMLElement, className: string) => {
    if (element && className) {
        element.classList.remove(className)
    }
}

export const setElementVisible = (elementId: string, visible: boolean) => {
    const el = document.getElementById(elementId)
    if (el) {
        if (visible) {
            removeClass(el, "hide")
        } else {
            addClass(el, "hide")
        }
    }
}

export const setElementAttribute = (elementId: string, name: string, value: string | number | boolean) => {
    const element = document.getElementById(elementId)
    if (element) {
        element.setAttribute(name, value + "")
    } else {
        console.warn("Could not locate element by id: " + elementId)
    }
}

export const setElementAttributeByClassname = (identifyingClassname: string, name: string, value: string | number | boolean) => {
    const els = document.getElementsByClassName(identifyingClassname)
    if (els != null) {
        for (let i = 0; i < els.length; i++) {
            const el = els[i];
            el.setAttribute(name, value + "")
        }
    }
}

export const getOrFail = (details: DOMStringMap, key: string) => {
    if (details === undefined) throw new Error("No dataset found");
    if (key === undefined) throw new Error("Invalid key " + key);
    const val: string | undefined = details[key];
    if (val === undefined) throw new Error("No value for " + key + " found in dataset");
    return val;
}

export const getOptionalStringValue = (obj: any, key: string, lang: Language): string | undefined => {
    let val = obj[key]
    if (val && val.length > 0) return val;
    val = obj[key + "_" + lang + "_s"]
    if (val && val.length > 0) return val;
    val = obj[key + "_" + lang + "_t"]
    if (val && val.length > 0) return val;
    val = obj[key + "_" + lang + "_html"]
    if (val && val.length > 0) return val;
    return undefined
}
