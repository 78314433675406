import {FaXTwitter} from '../icons/faXTwitter.tsx';
// import {FaLinkedIn} from '../icons/faLinkedIn.tsx';
import GetLabel from "../language/GetLabel.tsx";

const SharePageReact = () => {

    const onTwClickHandler = (curLocation: string | Location) => {
        window.open("https://twitter.com/share?url=" + curLocation, "_blank");
    }

    // const onLiClickHandler = (curLocation: string | Location) => {
    //     window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + curLocation, "_blank");
    // }

    // const linkedInURL = window.location.href.replace("=", "%3D");

    return (
        <div className="share-container" itemScope itemType="https://schema.org/ShareAction">
            <span tabIndex={0}><GetLabel name="SHARE"/></span>
            <button className="shBtns twBtn" onClick={() => onTwClickHandler(window.location.href)} itemProp="potentialAction"><FaXTwitter/> </button>
            {/* <button className="shBtns liBtn" onClick={() => onLiClickHandler(linkedInURL)} itemProp="potentialAction"><FaLinkedIn/> </button> */}
        </div>
    )
}

export default SharePageReact;