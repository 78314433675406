import {DateTime} from "luxon";
import cpacStore from "../cpac-store.ts";
import {config} from "../config.ts";

export const parseDate = (str: string | undefined) => {
    if (!str) return undefined
    if (str == "") return undefined
    const epochMillis = Date.parse(str);
    const dt = DateTime.fromMillis(epochMillis)
    return dt;
}

export const isToday = (date: DateTime) => {
    const today = DateTime.now().toFormat("EEEE");
    const theDate = date.toFormat("EEEE")
    return today == theDate;
}

export const setUserTimeZone = (tz: string) => {
    localStorage.setItem("cpacSelectedTimeZone", tz)
    cpacStore.setTimezone(tz)
}

export const initUserTimeZone = () => {
    const tz = localStorage.getItem("cpacSelectedTimeZone")
    if (tz) {
        cpacStore.setTimezone(tz)
    } else {
        const autoTZ = Intl.DateTimeFormat().resolvedOptions().timeZone
        if (autoTZ) {
            setUserTimeZone(autoTZ)
        } else {
            setUserTimeZone(config.defaultTimeZone)
        }
    }
}