import store, {CpacStoreState} from "../cpac-store.ts";
import {useSyncExternalStore} from "react";
import {fetchContentModel} from "./backend.ts";
import useSWR from "swr";
import {ItemListClass} from "../types/ItemListClass.ts";


export const useStore = (selector = (state: CpacStoreState) => state) => useSyncExternalStore(store.subscribe, () => selector(store.getState()))


export const useItemList = (listName: string, storeUrl: string, id: string | undefined) => {
    const lang = useStore().lang

    const {data, error, isLoading: loading} = useSWR({storeUrl, id, listName, lang}, () => fetchContentModel(storeUrl, id)
        .then(data => {
            if (data) {
                if (data.page['content-type'] == '/page/landing') {
                    return data.page.landingPage[listName]
                } else {
                    return data.page[listName];
                }
            } else {
                throw new Error("fetchContentModel returned no data for " + storeUrl + (id ? " id: " + id : ""))
            }
        })
        .then(rawList => {
            if (rawList) {
                return ItemListClass.constructInstance(rawList, lang)
            } else {
                console.log(`No item list found at ${storeUrl} for list ${listName}`)
                return undefined
            }
        }))

    return {loading, list: data, error}
}