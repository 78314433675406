import styled from 'styled-components';

const GridHolder = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(200px, 1fr));
  gap: 15px 15px;
  margin-top: 10px;

  @media only screen and (max-width: 920px) {
    grid-template-columns: 1fr;
  }

  .grid-item {
    margin: auto;

    img {
      max-width: 100%;
      object-fit: contain;
      height: 200px;
      object-fit: contain;
      transform: scale(0.95);
      opacity: 1;
      transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear, color 0.1s linear, opacity 0.1s linear, text-shadow 0.1s linear, transform 0.1s linear;

      &:hover {
        transform: scale(1);
        opacity: 1;
        transition: background-color 0.1s linear, border-color 0.1s linear, box-shadow 0.1s linear, color 0.1s linear, opacity 0.1s linear, text-shadow 0.1s linear, transform 0.1s linear;
      }
    }
  }
`;

export default GridHolder;