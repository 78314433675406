import React, {useState} from "react";
import YTVideo from "./YTVideo.tsx";
import {getOrFail} from "../../utils/common-utils.ts";
import ReactDOM from "react-dom/client";

type YouTubeVideoContainerProps = {
    id: string,
    playlist: string,
    title: string,
}
export const renderYouTube = (div: string) => {
    const root = document.getElementById(div)
    if (root == null) return
    const playlist = getOrFail(root.dataset, "playlist")
    const title = getOrFail(root.dataset, "title");
    ReactDOM.createRoot(root).render(<React.StrictMode>
        <YouTubeVideoContainer id={div} playlist={playlist} title={title}/></React.StrictMode>)
}

const YouTubeVideoContainer = (props: YouTubeVideoContainerProps) => {
    const [selected, setSelected] = useState(false);
    return (
        <div>
            <YTVideo
                selected={selected}
                setSelected={(val: any) => setSelected(val)}
                playlistId={props.playlist}
                title={props.title}/>
        </div>
    );
};

export default YouTubeVideoContainer;