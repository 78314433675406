/*
 * Important note about images.  To take advantage of automatic properly-sized image
 * selection as well as lazy-loading images, be sure to define your image tags like
 * this:
 * <img loading="lazy" class="multi-thumb" data-src="url-for-full-sized-image" />
 * i.e.
 * <img loading="lazy" class="multi-thumb" data-src="<@cpac.attr result "image" />" />
 * You must specify the loading attribute, the multi-thumb class, and the full-sized
 * image URL in the data-src attribute.  DO NOT SPECIFY THE src ATTRIBUTE. It will
 * be set automatically by the image handling system.
 */
import {useMediaQuery} from "react-responsive";

/**
 * Use a smaller version of the specified image.  Requires the image format to
 * be /a/path/of/unspecified/length/the_file.some_ext_like_png.
 * @param imagePath the original image path.  If null is passed, null is returned.
 * @param size (one of "sm", "md", "lg", "full")
 * @returns {string|*} the path to the resized image, or the small version if a
 * resized image cannot be computed (or the original image if the user specified "full").  Note this
 * function does not verify that the image exists it just assumes that it is there.
 */
const smallerImage = (imagePath: string, size: string) => {
    if (!imagePath) {
        console.error("Null image path not allowed.")
        return imagePath
    }
    if (size === "full") return imagePath
    if (size === "sm" || size === "md" || size === "lg") {
        const lastDot = imagePath.lastIndexOf('.')
        if (lastDot >= 0 && lastDot < imagePath.length) {
            const base = imagePath.substring(0, lastDot)
            const ext = imagePath.substring(lastDot + 1)
            return base + "_" + size + "." + ext;
        } else {
            console.error("Invalid file path - it does not contain a dot - for image '" + imagePath + "'. Cannot determine smaller image path. Returning original image.")
            return imagePath
        }
    } else {
        console.error("Invalid image file size '" + size + "' specified for image '" + imagePath + "'. One of 'sm', 'md', 'lg' or 'full' must be specified. Returning original image.")
        return imagePath
    }
}

export const useAppropriateImage = (fullSizeImagePath: string) => {
    const sz = useImageSize();
    return smallerImage(fullSizeImagePath, sz);
}


const useImageSize = () => {
    // lg 768 (1536 retina), md 576 (1152 retina), sm 269 (538 retina)
    // full
    const is1090_ = useMediaQuery({query: '(min-width: 1090px)'})
    const is769_920 = useMediaQuery({query: '(min-width: 769px) and (max-width: 920px)'})
    // lg
    const is577_768 = useMediaQuery({query: '(min-width: 577px) and (max-width: 768px)'})
    const is921_1089 = useMediaQuery({query: '(min-width: 921px) and (max-width: 1089px)'})

    const isMd = useMediaQuery({query: '(min-width: 270px) and (max-width: 576px)'})
    const isSm = useMediaQuery({query: '(max-width: 269px)'})
    const imgSz = (is1090_ || is769_920) ? "full" : (is577_768 || is921_1089) ? "lg" : isMd ? "md" : isSm ? "sm" : "full";
    // console.log("ideal image size computed to be", imgSz)
    return imgSz;
}

export const loadAppropriateImages = () => {
    const lazyloadImages = document.querySelectorAll("img.multi-thumb");

    const lazyload = () => {
        const scrollTop = window.pageYOffset;
        lazyloadImages.forEach(function (img) {
            if (img instanceof HTMLImageElement) {
                if (img.offsetTop < (window.innerHeight + scrollTop)) {
                    const origSrc = img.dataset.src
                    if (origSrc != null) {
                        const sm = smallerImage(origSrc, "sm")
                        const md = smallerImage(origSrc, "md")
                        const lg = smallerImage(origSrc, "lg")
                        /*
                            // lg 768 (1536 retina), md 576 (1152 retina), sm 269 (538 retina)
                            const isFull = useMediaQuery({ query: '(min-width: 769px)' })
                            const isLg = useMediaQuery({query: '(min-width: 577px) and (max-width: 768px'})
                            const isMd = useMediaQuery({query: '(min-width: 270px) and (max-width: 576px'})
                            const isSm = useMediaQuery({query: '(max-width: 269px'})
                        */
                        img.src = sm // low resolution placeholder
                        img.srcset = sm + " 269w, " + md + " 576w, " + lg + " 768w, " + origSrc + " 1536w"
                        // console.debug("srcset:",img.srcset)
                        img.classList.remove('lazy');
                    }
                }
            }
        });
    }

    lazyload()
}

