export const renderSlideshow = (div: string, next: string, prev: string, item: string, visible: string, hidden: string) => {

    if (document.getElementById(div)) {
        const slides = document.getElementsByClassName(item);
        const nextButton: HTMLElement | null = document.getElementById(next);
        const prevButton: HTMLElement | null = document.getElementById(prev);
        let position = 0;
        const numberOfSlides = slides.length;

        function hideAllSlides() {
            // remove all slides not currently being viewed
            for (const slide of slides) {
                slide.classList.remove(visible);
                slide.classList.add(hidden);
            }
        }

        const handleMoveToNextSlide = function() {
            hideAllSlides();
        
            // check if last slide has been reached
            if (position === numberOfSlides - 1) {
                position = 0; // go back to first slide
            } else {
                // move to next slide
                position++;
            }
            // make current slide visible
            slides[position].classList.add(visible);
        }
        
        const handleMoveToPrevSlide = function() {
            hideAllSlides();
        
            // check if we're on the first slide
            if (position === 0) {
                position = numberOfSlides - 1; // move to the last slide
            } else {
                // move back one
                position--;
            }
            // make current slide visible
            slides[position].classList.add(visible);
        }
        if (nextButton) {
            nextButton.addEventListener("click", handleMoveToNextSlide);
        }
        if (prevButton) {
            prevButton.addEventListener("click", handleMoveToPrevSlide); 
        }
    }
    
}