import {setElementAttribute, setElementAttributeByClassname, setElementVisible, ssg, sss} from "./common-utils.ts";
import store from "../cpac-store.ts";
import {resizeElement} from "./responsive.ts";
import {loadAppropriateImages} from "./image-utils.ts";

export const header_hideAll = () => {
    sss("sideMenuActive", false)
    sss("searchBarActive", false)
    sss("liveBarActive", false)
    sss("backDropActive", false)
}
export const header_hambClickHandler = () => {
    // @ts-ignore todo: figure out why this doesn't work with Typescript
    ssg('currentModalElement') && document.querySelectorAll(`#${ssg('currentModalElement')}`)[0].focus();
    if (ssg("sideMenuActive")) {
        header_hideAll()
    } else {
        sss("sideMenuActive", true)
        sss("backDropActive", true)
        sss("searchBarActive", false)
        sss("liveBarActive", false);
    }
    header_update()
}

export const header_handleEnterPress = (e: any, action: any) => {
    if (e.key === 'Enter' && e.target != null) {
        sss('currentModalElement', e.target.id);
        action(e);
        header_update()
    }
}

export const header_handleMenuClick = () => {
    header_hambClickHandler();
    setTimeout(() => {
        // @ts-ignore todo: figure this out
        document.querySelectorAll('.side-menu a')[0].focus();
    }, 300);
    header_update()
}

export const header_searchClickHandler = () => {
    // @ts-ignore todo: figure this out
    ssg('currentModalElement') && document.querySelectorAll(`#${ssg('currentModalElement')}`)[0].focus();
    if (ssg("searchBarActive")) {
        header_hideAll()
    } else {
        sss("searchBarActive", true)
        sss("backDropActive", true)
        sss("sideMenuActive", false)
        sss("liveBarActive", false)
    }
    header_update()
}

export const header_handleClickOutside = (e: Event) => {
    if (e != null && e.target != null) {
        const target = e.target;
        // @ts-ignore
        if (target.tagName != null && (target.tagName === "DIV" || target.tagName === "NAV" || target.tagName === "SPAN")) {
            header_hideAll();
            header_update()
        }

    }
}

export const loadLiveStreams = () => {
    fetch("/api/1/services/livestreams.json")
        .then(res => res.json())
        .then(data => data.component)
        .then(data => {
            // Add a timestamp to the livestream data for debug so that we know when it was last retrieved
            data = {timestamp: new Date(), ...data}
            store.setLivestream(data)
            // console.debug("Livestreams: ", data)
            let count = 0
            if (data && data.itemList && data.itemList.item) {
                for (const item of data.itemList.item) {
                    if (item.category !== "IGNORE") {
                        count++
                    }
                }
            }
            if (count > 0) {
                setElementVisible("liveBarClickHandler", true)
                const element = document.getElementById("header_livestream_counter")
                if (element) {
                    element.innerHTML = "" + count + ""
                } else {
                    console.warn("Could not set livestream count because the header_livestream_counter element was not present")
                }
            } else {
                setElementVisible("liveBarClickHandler", false)
            }
        })
}

export const header_liveBarClickHandler = () => {
    resizeElement('.live-container__item')
    // @ts-ignore todo: figure out why typescript doesn't like this
    ssg('currentModalElement') && document.querySelectorAll(`#${ssg('currentModalElement')}`)[0].focus();
    if (ssg("liveBarActive")) {
        header_hideAll()
    } else {
        sss("liveBarActive", true)
        sss("backDropActive", true)
        sss("searchBarActive", false)
        sss("sideMenuActive", false)
    }
    loadAppropriateImages()
    header_update()
}

export const header_rotateBreakingNews = () => {
    const container = document.querySelector(".header-news-holder .break-news")
    if (container) {
        const itemToMove = container.children[0];
        if (itemToMove) {
            container.appendChild(itemToMove);
        }
    }
}

export const header_update = () => {
    if (ssg("sideMenuActive")) {
        // handle the main menu effects
        setElementAttribute("hambClickHandler", "class", `header__brand-nav__hamb-menu inverted`)
        setElementAttribute("header-a-1", "class", "header__brand-nav__logo inverted")
        setElementAttribute("header-span-2", "class", "inverted ham-home")
        setElementVisible("hamb-menu-hamb", false)
        setElementVisible("hamb-menu-x", true)
        // handle the sidebar effects
        setElementAttribute("sidemenu-nav", "class", `side-menu expandLeft-appear`)
        setElementAttribute("sidemenu-nav", "tabindex", 0)
        setElementAttribute("sidemenu-nav", "aria-hidden", false)

    } else {
        // handle the main menu effects
        setElementAttribute("hambClickHandler", "class", `header__brand-nav__hamb-menu`)
        setElementAttribute("header-a-1", "class", "header__brand-nav__logo")
        setElementVisible("hamb-menu-hamb", true)
        setElementVisible("hamb-menu-x", false)
        // handle the sidebar effects
        setElementAttribute("sidemenu-nav", "class", `side-menu expandLeft-collapse`)
        setElementAttribute("sidemenu-nav", "tabindex", -1)
        setElementAttribute("sidemenu-nav", "aria-hidden", true)
    }

    if (ssg("searchBarActive")) {
        setElementVisible("header_multi_search_img_q", false)
        setElementVisible("header_multi_search_img_x", true)
        setElementAttribute("search-bar", "class", "search-bar expand")
        setElementAttribute("search-bar", "aria-hidden", false)
        setElementAttribute("search-bar", "tabindex", 0)
    } else {
        setElementVisible("header_multi_search_img_q", true)
        setElementVisible("header_multi_search_img_x", false)
        setElementAttribute("search-bar", "class", "search-bar collapse")
        setElementAttribute("search-bar", "aria-hidden", true)
        setElementAttribute("search-bar", "tabindex", -1)
    }

    if (ssg("backDropActive")) {
        setElementAttribute("backdrop", "class", "backdrop")
    } else {
        setElementAttribute("backdrop", "class", "backdrop backdrop-hidden")
    }

    if (ssg("liveBarActive")) {
        setElementAttributeByClassname("live-bar", "class", "live-bar expandDown-appear")
        setElementAttributeByClassname("live-bar", "aria-hidden", false)
        setElementAttributeByClassname("live-bar", "tab-index", 0)
    } else {
        setElementAttributeByClassname("live-bar", "class", "live-bar expandDown-collapse")
        setElementAttributeByClassname("live-bar", "aria-hidden", true)
        setElementAttributeByClassname("live-bar", "tab-index", -1)
    }
}