import {getOrFail} from "../utils/common-utils.ts";
import {ItemListItemClass} from "./ItemListItemClass.ts";
import {DateTime} from "luxon";

export class VideoPlayerDetails {
    constructor(
        readonly episodeId: string,
        readonly type: string,
        readonly videoUrl: string,
        readonly videoDuration: string,
        readonly liveDateTime: DateTime,
        readonly lastDateModified: DateTime,
        readonly title: string,
        readonly description: string,
        readonly category: string,
        readonly categoryURL: string,
        readonly image: string,
        readonly altImageTxt: string,
        readonly url: string,
    ) {
    }

    static createInstanceFromItemListItemClass(ili: ItemListItemClass) {
        return new VideoPlayerDetails(
            ili.episodeId!,
            ili.type!,
            ili.videoUrl!,
            ili.videoDuration!,
            ili.liveDateTime!,
            DateTime.now(), // not available in item lists!!!
            ili.title!,
            ili.description!,
            ili.category!,
            ili.categoryURL!,
            ili.image!,
            ili.altImageTxt!,
            ili.url!
        )
    }


    static createInstanceFromDOM(details: DOMStringMap, lang: string) {
        return new VideoPlayerDetails(
            getOrFail(details, "episodeid"),
            getOrFail(details, "type"),
            getOrFail(details, "videourl"),
            getOrFail(details, "videoduration"),
            VideoPlayerDetails.getDate(details, "livedatetime"),
            VideoPlayerDetails.getDate(details, "lastdatemodified"),

            "en" == lang ? getOrFail(details, "title_en_t") : getOrFail(details, "title_fr_t"),
            "en" == lang ? getOrFail(details, "description_en_t") : getOrFail(details, "description_fr_t"),
            "en" == lang ? getOrFail(details, "category_en_t") : getOrFail(details, "category_fr_t"),
            "en" == lang ? getOrFail(details, "categoryurl_en_s") : getOrFail(details, "categoryurl_fr_s"),
            "en" == lang ? getOrFail(details, "image_en_s") : getOrFail(details, "image_fr_s"),
            "en" == lang ? getOrFail(details, "altimagetxt_en_s") : getOrFail(details, "altimagetxt_fr_s"),
            ""
        );
    }


    private static getDate = (details: DOMStringMap, key: string) => {
        const val = getOrFail(details, key)
        // A note about date parsing.  ALL of the dates coming
        // in to JS from Crafter are required to include the time zone,
        // so the parsed date should be perfectly valid and with an actual
        // assigned time zone.
        // todo: as of 2023-09-04 there is 1 exception: episode.sortOrderDateTime_dt, which will be fixed on the server.
        const epochMillis = Date.parse(val)
        return DateTime.fromMillis(epochMillis)
    }
}