import {useStore} from "../utils/hooks.ts";
import {config} from "../config.ts";
import {DateTime} from "luxon";
import ld from '../svg/left-decoration.svg'
import {ItemListItemClass} from "../types/ItemListItemClass.ts";
import ReactDOM from "react-dom/client";
import React from "react";
import GetLabel from "../language/GetLabel.tsx";
import LiveCountdown from "../date/LiveCountdown.tsx";

export const renderLiveBar = (div: string) => {
    const root = document.getElementById(div)
    if (root) {
        ReactDOM.createRoot(root).render(<React.StrictMode><LiveContainer/></React.StrictMode>)
    }
}
const LiveContainer = () => {
    const state = useStore()
    const stream: Livestream | undefined = state.livestream;

    if (!stream) {
        return null
    }

    const renderLive = () => {
        return (
            <div className="video-live-label t-l">
                <img src="/static-assets/theme/svg/target.svg" alt="target-icon"/>
                <span><GetLabel name="LIVE"/></span>
            </div>
        )
    }

    const renderCpactv = () => {
        return (
            <div className="live-container__item__cpactv">
                <img src={ld} alt="left-decoration"/>
                <span>CPAC TV</span>
            </div>
        )
    }

    const renderSmallItemInfo = (item: ItemListItemClass) => {
        const minutesBefore = config.liveNotificationTime
        const now = DateTime.now()
        const alertTime = item.liveDateTime.minus(minutesBefore)
        const eventIsSoon = now > alertTime && now < item.liveDateTime;
        const alreadyStarted = now > item.liveDateTime;


        switch (item.type) {

            case "live":
                return (
                    <>
                        <div className="live-container__item__header">
                            <a href={item.url}><img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt}/></a>
                        </div>
                        <div className="live-container__item__description">
                            <a href={item.categoryURL} className="live-container__item__category">{item.category}</a>
                            <a href={item.url} className="live-container__item__title">{item.title}</a>
                        </div>
                        {renderLive()}
                    </>
                )
                break;
            case "prelive":
                if (eventIsSoon === false) {
                    if (alreadyStarted) {
                        return (
                            <>
                                <div className="live-container__item__header">
                                    <a href={item.url}><img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt}/></a>
                                </div>
                                <div className="live-container__item__description">
                                    <a href={item.categoryURL} className="live-container__item__category">{item.category}</a>
                                    <a href={item.url} className="live-container__item__title">{item.title}</a>
                                </div>
                                {renderLive()}
                            </>
                        )
                    } else {
                        return (
                            <>
                                <div className="live-container__item__header">
                                    <img loading="lazy" className="multi-thumb f-item" data-src={item.image} alt={item.altImageTxt}/>
                                    <div className="live-container__item__live-info" tabIndex={0}>
                                        <LiveCountdown liveDateTime={item.liveDateTime} />
                                    </div>
                                </div>
                                <div className="live-container__item__description">
                                    <p className="live-container__item__category" tabIndex={0}>{item.category}</p>
                                    <p className="live-container__item__title" tabIndex={0}>{item.title}</p>
                                </div>
                            </>
                        )
                    }
                } else {
                    return (
                        <>
                            <div className="live-container__item__header">
                                <a href={item.url}><img loading="lazy" className="multi-thumb f-item" data-src={item.image} alt={item.altImageTxt}/></a>
                                <div className="live-container__item__live-info">
                                    <GetLabel name="LIVE"/>
                                    <LiveCountdown liveDateTime={item.liveDateTime} />
                                </div>
                            </div>
                            <div className="live-container__item__description">
                                <a href={item.categoryURL} className="live-container__item__category">{item.category}</a>
                                <a href={item.url} className="live-container__item__title">{item.title}</a>
                            </div>
                        </>
                    )
                }
                break;
            case "cpactv":
                return (
                    <>
                        <div className="live-container__item__header">
                            <a href={item.url}><img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt}/></a>
                        </div>
                        <div className="live-container__item__description">
                            <a href={item.categoryURL} className="live-container__item__category">{item.category}</a>
                            <a href={item.url} className="live-container__item__title">{item.title}</a>
                        </div>
                        {renderCpactv()}
                    </>
                )
                break;
            default:
                return (
                    <>
                        <div className="live-container__item__header">
                            <a href={item.url}><img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt}/></a>
                        </div>
                        <div className="live-container__item__description">
                            <a href={item.categoryURL} className="live-container__item__category">{item.category}</a>
                            <a href={item.url} className="live-container__item__title">{item.title}</a>
                        </div>
                    </>
                )
        }
    }

    const renderItems = () => {
        return stream.itemList.item.map((item, i) => {
            const localizedItem = ItemListItemClass.constructInstanceFromItemList(item, state.lang)
            if (localizedItem.category == "IGNORE") return null
            return (
                <li key={i} className={`live-container__item`}>
                    {renderSmallItemInfo(localizedItem)}
                </li>
            );
        });
    }

    return (
        <ul className="live-container">
            {renderItems()}
        </ul>
    );
};

export default LiveContainer;