import {ItemListViewProps} from "../../types/ItemListViewProps.ts";
import HnCarouselHolder from "./HnCarouselStyles.tsx";
import {truncateWords} from "../../utils/search-utils.ts";
import GetLabel from "../../language/GetLabel.tsx";
import {useEffect} from 'react';
import {resizeElement} from "../../utils/responsive.ts";
import {loadAppropriateImages} from "../../utils/image-utils.ts";

const HnCarousel = (props: ItemListViewProps) => {
    const itemList = props.itemList;
    if (!itemList) return;

    useEffect(() => {
        resizeElement('.carousel-img');
        loadAppropriateImages();
    });

    return (
        <HnCarouselHolder>
            <section className="hn__content" tabIndex={0} aria-label={itemList.title} itemScope={true} itemType='https://schema.org/WPSideBar'>
                <h2 className="hn__Header" itemProp='name'>
                    {itemList.title}
                    {itemList.seeallbtn && // only if see all button is checked, show the button/link
                        <a href={itemList.seeAllUrl} className="seeAllBtn">
                            <GetLabel name="SEEALL"/>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>
                        </a>
                    }
                </h2>
                <ul itemScope={true} itemType="https://schema.org/ItemList">
                    {itemList.items.map((item, i) => {
                        if (item.image == undefined) return;
                        return (
                            <li key={i} className="hn-carousel-item" itemProp="itemListElement">
                                <div className="header__container">
                                    <a href={item.url} target={item.openInNewTab ? "_blank" : "_self"} itemProp='url'>
                                        <img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt} tabIndex={0} itemProp={'image'}/>
                                    </a>
                                </div>
                                <div className="description__container">
                                    {item.category && (
                                        <a href={item.url} target={item.openInNewTab ? "_blank" : "_self"}>
                                            <h2 itemProp='subjectOf'>{item.category}</h2>
                                        </a>
                                    )}
                                    <a href={item.url} itemProp='url'>
                                        <p itemProp='description'>
                                            {item.title && truncateWords(item.title, 225)}
                                            {item.title && item.title.length > 225 && (
                                                <span>...</span>
                                            )}
                                        </p>
                                    </a>
                                </div>
                            </li>
                        )
                    })}
                </ul>
                {itemList.items.length > 4 && itemList.seeallbtn && // only if items are more than 4 and see all button is checked, show the banner/button/link
                    <h2 className="hn__Header-bottom">
                        <a href={itemList.seeAllUrl} className="seeAllBtn">
                            <GetLabel name="SEEALL"/>
                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                            </svg>
                        </a>
                    </h2>
                }
            </section>
        </HnCarouselHolder>
    );
};

export default HnCarousel;