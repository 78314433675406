import GetLabel from "../../language/GetLabel.tsx";

const LiveLabel = () => {
    return (
        <div className="video-live-label t-l">
            <img src="/static-assets/theme/svg/target.svg" alt="target-icon"/>
            <span><GetLabel name={"LIVE"}/></span>
        </div>
    )
};

export default LiveLabel;