import { useEffect, useState } from "react";

import { ItemListViewProps } from "../../types/ItemListViewProps.ts";
import { config } from "../../config.ts";
import Video from "../../video/jw/Video.tsx";
import { DateTime } from "luxon";
import { loadAppropriateImages } from "../../utils/image-utils.ts";
import RelativeTime from "../../date/RelativeTime.tsx";
import { VideoPlayerDetails } from "../../types/VideoPlayerDetails.ts";
import FeaturedItemHolder from "./FeaturedItemStyles.tsx";
import { ItemListItemClass } from "../../types/ItemListItemClass.ts";
import { resizeElement } from "../../utils/responsive.ts";
import LiveLabel from "./LiveLabel.tsx";
import CpacTvLabel from "./CpacTvLabel.tsx";
import GetLabel from "../../language/GetLabel.tsx";
import LiveCountdown from "../../date/LiveCountdown.tsx";

const FeaturedItems = (props: ItemListViewProps) => {
  const [showCPACTV, setShowCPACTV] = useState(true);

  useEffect(() => {
    loadAppropriateImages();
    resizeElement(".featured-container__small-item__info");
  });

  const renderSmallItemInfo = (item: ItemListItemClass) => {
    if (!item.image) return;

    switch (item.type) {
      case "article":
        return (
          <>
            <div className="featured-container__small-item__info__header">
              <a href={item.url}>
                <img
                  loading="lazy"
                  className="multi-thumb"
                  data-src={item.image}
                  alt={item.altImageTxt}
                  itemProp="image"
                />
              </a>
            </div>
            <div className="featured-container__small-item__info__description">
              <a
                href={item.url}
                className="featured-container__small-item__info__category"
              >
                <span itemProp="subjectOf">{item.category}</span>
              </a>
              <a
                href={item.url}
                className="featured-container__small-item__info__title"
              >
                <span itemProp="name">{item.title}</span>
              </a>
            </div>
          </>
        );
      case "live":
        return (
          <>
            <div className="featured-container__small-item__info__header">
              <a href={item.url} rel="noreferrer">
                <img
                  loading="lazy"
                  className="multi-thumb"
                  data-src={item.image}
                  alt={item.altImageTxt}
                  itemProp="image"
                />
              </a>
            </div>
            <div className="featured-container__small-item__info__description">
              <a
                href={item.url}
                className="featured-container__small-item__info__category"
              >
                <span itemProp="subjectOf">{item.category}</span>
              </a>
              <a
                href={item.url}
                className="featured-container__small-item__info__title"
              >
                <span itemProp="name">{item.title}</span>
              </a>
            </div>
            <LiveLabel />
          </>
        );
      case "prelive":
        const now = DateTime.now();
        const alertTime = item.liveDateTime.minus(config.liveNotificationTime);
        const eventIsSoon = now > alertTime && now < item.liveDateTime;
        const alreadyStarted = now > item.liveDateTime;

        if (alreadyStarted) {
          // The state should not be prelive here
          // but the vualto sync may not have changed
          // the state yet... so we need to show the
          // video anyway.
          return (
            <>
              <div className="featured-container__small-item__info__header">
                <a href={item.url} rel="noreferrer">
                  <img
                    loading="lazy"
                    className="multi-thumb"
                    data-src={item.image}
                    alt={item.altImageTxt}
                    itemProp="image"
                  />
                </a>
              </div>
              <div className="featured-container__small-item__info__description">
                <a
                  href={item.url}
                  className="featured-container__small-item__info__category"
                >
                  <span itemProp="subjectOf">{item.category}</span>
                </a>
                <a
                  href={item.url}
                  className="featured-container__small-item__info__title"
                >
                  <span itemProp="name">{item.title}</span>
                </a>
              </div>
              <LiveLabel />
            </>
          );
        }
        if (eventIsSoon) {
          // Show links and countdown
          return (
            <>
              <div className="featured-container__small-item__info__header">
                <a href={item.url}>
                  <img
                    loading="lazy"
                    className="multi-thumb"
                    data-src={item.image}
                    alt={item.altImageTxt}
                    itemProp="image"
                  />
                </a>
                <div className="featured-container__small-item__live-info">
                  <GetLabel name={"LIVE"} />{" "}
                  <RelativeTime date={item.liveDateTime} />
                </div>
              </div>
              <div className="featured-container__small-item__info__description">
                <a
                  href={item.url}
                  className="featured-container__small-item__info__category"
                >
                  <span itemProp="subjectOf">{item.category}</span>
                </a>
                <a
                  href={item.url}
                  className="featured-container__small-item__info__title"
                >
                  <span itemProp="name">{item.title}</span>
                </a>
              </div>
            </>
          );
        }

        // Just show when it's scheduled
        return (
          <>
            <div className="featured-container__small-item__info__header">
              <a>
                <img
                  loading="lazy"
                  className="multi-thumb"
                  data-src={item.image}
                  alt={item.altImageTxt}
                  itemProp="image"
                />
              </a>
              <div className="featured-container__small-item__live-info">
                <LiveCountdown liveDateTime={item.liveDateTime} />
              </div>
            </div>
            <div className="featured-container__small-item__info__description">
              <p className="featured-container__small-item__info__category">
                <span itemProp="subjectOf">{item.category}</span>
              </p>
              <p className="featured-container__small-item__info__title">
                <span itemProp="name">{item.title}</span>
              </p>
            </div>
          </>
        );
      case "cpactv":
        return (
          <>
            <div className="featured-container__small-item__info__header">
              <a href={item.url}>
                <img
                  loading="lazy"
                  className="multi-thumb"
                  data-src={item.image}
                  alt={item.altImageTxt}
                  itemProp="image"
                />
              </a>
            </div>
            <div className="featured-container__small-item__info__description">
              <a
                href={item.categoryURL}
                className="featured-container__small-item__info__category"
              >
                <span itemProp="subjectOf">{item.category}</span>
              </a>
              <a
                href={item.url}
                className="featured-container__small-item__info__title"
              >
                <span itemProp="name">{item.title}</span>
              </a>
            </div>
            <CpacTvLabel showCPACTV={showCPACTV} index={0} />
          </>
        );
      default:
        // Not prelive or live (or cpactv), so it's a little further in the future,
        // or it is a VOD. Don't show any special labels.
        return (
          <>
            <div className="featured-container__small-item__info__header">
              <a href={item.url}>
                <img
                  loading="lazy"
                  className="multi-thumb"
                  data-src={item.image}
                  alt={item.altImageTxt}
                  itemProp="image"
                />
              </a>
            </div>
            <div className="featured-container__small-item__info__description">
              <a
                href={item.url}
                className="featured-container__small-item__info__category"
              >
                <span itemProp="subjectOf">{item.category}</span>
              </a>
              <a
                href={item.url}
                className="featured-container__small-item__info__title"
              >
                <span itemProp="name">{item.title}</span>
              </a>
            </div>
          </>
        );
    }
  };
  return (
    <FeaturedItemHolder>
      <section
        className="featured-container"
        aria-label="featured-container"
        tabIndex={0}
        itemScope
        itemType="https://schema.org/ItemList"
      >
        {props.itemList.items.map((item, i) => {
          if (i === 0) {
            const id = `featured-container__big-item-id_${i}`;
            return (
              <div
                id={id}
                key={i}
                className={`featured-container__big-item ${
                  props.itemList.items.length === 1 ? "only-item" : ""
                }`}
                itemProp="itemListElement"
              >
                {item.type === "cpactv" ? (
                  <CpacTvLabel showCPACTV={showCPACTV} index={i} />
                ) : (
                  <></>
                )}
                {item.type === "live" ? (
                  <div className="featured-container__big-item__live-label-holder">
                    <LiveLabel />
                  </div>
                ) : (
                  <></>
                )}
                <Video
                  id={id}
                  details={VideoPlayerDetails.createInstanceFromItemListItemClass(
                    item,
                  )}
                  setShowCPACTV={setShowCPACTV}
                  processPrelive={true}
                  hOnlyItem={props.itemList.items.length === 1}
                  isLanding={false}
                />
              </div>
            );
          } else {
            return (
              <div
                key={i}
                className="featured-container__small-item"
                tabIndex={0}
                itemProp="itemListElement"
              >
                <div className="featured-container__small-item__info">
                  {renderSmallItemInfo(item)}
                </div>
              </div>
            );
          }
        })}
      </section>
    </FeaturedItemHolder>
  );
};
export default FeaturedItems;
