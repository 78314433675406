type YTVideoItemProps = {
    video: any,
    onVideoSelect: any
}
const YTVideoItem = (props: YTVideoItemProps) => {
    const {video, onVideoSelect} = props
    return (
        <div className={"yt-video-item-holder"}>
            <div onClick={() => onVideoSelect(video)} onKeyPress={() => onVideoSelect(video)} className="yt-video-item" tabIndex={0} itemProp="itemListElement">
                {video.snippet.thumbnails.medium &&
                    <img src={video.snippet.thumbnails.medium.url} alt={video.snippet.title} itemProp='image'/>}
                <div className="yt-video-description" itemProp='description'>
                    {video.snippet.title}
                </div>
            </div>
        </div>
    )
}

export default YTVideoItem;