import './css/cpac.css'
import {renderFormContactEn} from "./forms/FormContactEn.tsx";
import {renderFormContactFr} from "./forms/FormContactFr.tsx";
import {renderFormVideoHelpEn} from "./forms/FormVideoHelpEn.tsx";
import {renderFormVideoHelpFr} from "./forms/FormVideoHelpFr.tsx";
import {renderFormFeedbackEn} from "./forms/FormFeedbackEn.tsx";
import {renderFormFeedbackFr} from "./forms/FormFeedbackFr.tsx";
import {renderItemList} from "./itemlist/ItemList.tsx";
import {renderLiveBar} from "./livebar/LiveContainer.tsx";
import {renderVideo} from "./video/jw/Video.tsx";
import {renderYouTube} from "./video/yt/YouTubeVideoContainer.tsx";
import {renderCookieBar} from "./cookielaw/CookieLawBar.tsx";
import {renderFormInYourArea} from "./in-your-area-search/InYourAreaSearch.tsx";
import {crafterConf} from "@craftercms/classes";
import {initUserLanguage, loadLocaleStrings} from "./language/lang-utils.ts";
import {initializeEventListeners} from "./utils/cpacEventListeners.ts";
import {initUserTimeZone} from "./date/util.ts";
import { renderSlideshow } from './slideshow/slideshow.tsx';

// init crafter client
crafterConf.configure({
    site: document.getElementById('head')?.dataset['site'] || ''
});


// init language
initUserLanguage()


// init timezone
initUserTimeZone()


// pre-load localized strings
loadLocaleStrings()


// init event listeners
initializeEventListeners()


// attach react components
renderLiveBar('live-bar')
renderCookieBar("cookie-law-bar-holder")

renderItemList("landing-latest-vids")
renderItemList("landing-top-list")
renderItemList("landing-bottom-list")
renderItemList("home-tile-list")
renderItemList("home-livestreams")
renderItemList("home-latest-videos")
renderItemList("article-item-list")
renderItemList("article-item-list-bottom")
renderItemList("portal-livestreams")
renderItemList("portal-question-period")
renderItemList("portal-proceedings")
renderItemList("portal-special-bills")
renderItemList("portal-last-house-videos")
renderItemList("video-page-latest-videos")
renderItemList("program-side-item-list")
renderItemList("shows-page-grid-view")

renderVideo('lp-video-player')
renderVideo('video-page-video')
renderYouTube("landing-interview-yt")
renderYouTube("landing-explainers-yt")

renderFormContactEn('component-form-contact-en')
renderFormContactFr('component-form-contact-fr')
renderFormVideoHelpEn('component-form-video-help-en')
renderFormVideoHelpFr('component-form-video-help-fr')
renderFormFeedbackEn('component-form-accessibility-en')
renderFormFeedbackFr('component-form-accessibility-fr')
renderFormInYourArea("in-your-area-form")
renderSlideshow('slideshow1', 'slideshow1-button-next', 'slideshow1-button-prev', 'slideshow1-item', 'slideshow1-item-visible', 'slideshow1-item-hidden')
renderSlideshow('slideshow2', 'slideshow2-button-next', 'slideshow2-button-prev', 'slideshow2-item', 'slideshow2-item-visible', 'slideshow2-item-hidden')
renderSlideshow('slideshow3', 'slideshow3-button-next', 'slideshow3-button-prev', 'slideshow3-item', 'slideshow3-item-visible', 'slideshow3-item-hidden')