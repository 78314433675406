import {ItemListViewProps} from "../../types/ItemListViewProps.ts";
import VCarouselHolder from "./VCarouselStyles.tsx";
import {useEffect, useState} from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {truncateWords} from "../../utils/search-utils.ts";
import {loadAppropriateImages} from "../../utils/image-utils.ts";
import GetLabel from "../../language/GetLabel.tsx";

const VCarousel = (props: ItemListViewProps) => {
    const {itemList} = props
    const [page, setPage] = useState(0)
    if (itemList === undefined) {
        console.warn("No item list to render in VCarousel")
        return
    }
    const pageSize = itemList.pageSize == 0 ? 10 : itemList.pageSize
    // This could be parameterized...
    const maxPages = 5;

    useEffect(() => {
        loadAppropriateImages()
    });

    const isLastPage = () => {
        const pgs = Math.ceil(itemList.items.length / pageSize)
        const max = Math.min(pgs, maxPages)
        return page == (max - 1) // page is a 0-based array index
    }

    const showPrev = () => {
        setPage(page => {
            if (page == 0) return 0;
            return page - 1
        })
    }

    const showNext = () => {
        setPage(page => {
            if (!isLastPage())
                return page + 1
            else return 0
        })
    }

    const renderItems = () => {
        const selected = itemList.items.slice(page * pageSize, (page + 1) * pageSize);
        return selected.map((item, i) => {
            if (item.image === undefined) return;
            return (
                <li key={`v-items-grp-${i}`} itemProp='itemListElement'>
                    <ReactCSSTransitionGroup
                        transitionName="fade"
                        transitionEnterTimeout={500}
                        transitionLeaveTimeout={300}
                        transitionAppear={true}
                        transitionAppearTimeout={2000}>
                        <div className="latest-container__item">
                            <a href={item.url} className="latest-container__item__img">
                                <img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt} itemProp='image'/>
                                {item.videoDuration && <div className="latest-container__item__img__date-container" aria-hidden="true">
                                    {item.videoDuration}
                                </div>}
                            </a>
                            <div className="latest-container__item__info">
                                <a href={item.categoryURL} className="latest-container__item__info__category" itemProp='subjectOf'>{item.category}</a>
                                <a href={item.url} className="latest-container__item__info__title" itemProp='description'>
                                    {item.title && truncateWords(item.title, 88)}
                                    {item.title && item.title.length > 88 && (
                                        <span className="description__ellipsis">...</span>
                                    )}
                                </a>
                            </div>
                        </div>
                    </ReactCSSTransitionGroup>
                </li>
            )
        })
    }

    return (
        <VCarouselHolder>
            <div tabIndex={0} aria-label={itemList.title}>
                <h2 itemProp='name'>{itemList.title}</h2>
                <a className={`${itemList.paginated && itemList.seeallbtn ? "seeAllTopBtn" : "hidden"}`} href={itemList.seeAllUrl}>
                    <GetLabel name="VIEW_ALL"/>
                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right" className="svg-inline--fa fa-arrow-right fa-w-14 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                        <path fill="currentColor" d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
                    </svg>
                </a>
                <ul className="latest-container" itemScope itemType='https://schema.org/ItemList'>
                    {renderItems()}
                </ul>
                {!itemList.paginated && itemList.seeallbtn && <div className="latest-container__btns-container">
                    <a href={itemList.seeAllUrl} className="seeAll-btn">
                        <GetLabel name="SEEALL"/>
                    </a></div>}
                {itemList.paginated && <div className={`${itemList.paginated && itemList.items.length > pageSize ? "latest-container__btns-container" : "hidden"}`}>
                    <button disabled={page === 0} onClick={showPrev}>
                        <svg aria-labelledby="svg-inline--fa-title-bVoow2Qlmudh" data-prefix="fas" data-icon="chevron-left"
                             className="svg-inline--fa fa-chevron-left fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512" aria-hidden="true"><title id="svg-inline--fa-title-bVoow2Qlmudh">previous</title>
                            <path fill="currentColor"
                                  d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path>
                        </svg>
                        <GetLabel name="PREVIOUS"/>
                    </button>
                    <button disabled={isLastPage()} onClick={showNext}>
                        <GetLabel name="NEXT"/>
                        <svg aria-labelledby="svg-inline--fa-title-SC4oNuPwHgp3" data-prefix="fas" data-icon="chevron-right"
                             className="svg-inline--fa fa-chevron-right fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 320 512" aria-hidden="true"><title id="svg-inline--fa-title-SC4oNuPwHgp3">next</title>
                            <path fill="currentColor"
                                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path>
                        </svg>
                    </button>
                </div>}
            </div>
        </VCarouselHolder>
    );
};

export default VCarousel;