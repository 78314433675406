export const share_onFaClickHandler = () => {
    window.open("https://www.facebook.com/sharer/sharer.php?u=" + window.location.href, "_blank");
}

export const share_onTwClickHandler = () => {
    window.open("https://twitter.com/share?url=" + window.location.href, "_blank");
}

const linkedInURL = window.location.href.replace("=", "%3D");
export const share_onLiClickHandler = () => {
    window.open("https://www.linkedin.com/sharing/share-offsite/?url=" + linkedInURL, "_blank");
}