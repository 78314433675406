import {ssg, sss} from "./common-utils.ts";
import cpacStore from "../cpac-store.ts";
import {DateTime} from "luxon";
import {Language} from "../language/Language.ts";
import {setUserTimeZone} from "../date/util.ts";

export const schedule_setSelectedTimeZone = (selectedTimeZoneCode: string) => {
    if (selectedTimeZoneCode) {
        setUserTimeZone(selectedTimeZoneCode)
        location.reload()
    }
}

export const schedule_formatTimes = () => {
    const state = cpacStore.getState()

    let prevDate = ""
    document.querySelectorAll(".date-label--mobile").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.airdate) {
                const date = DateTime.fromISO(e.dataset.airdate).setLocale(state.lang + "-CA").setZone(state.timezone)
                const fdate = date.toFormat(state.lang == Language.en ? "MMMM d" : "d MMMM")
                e.innerHTML = fdate
                if (prevDate != fdate) {
                    e.classList.remove("hidden")
                }
                prevDate = fdate;
            }
        }
    })

    prevDate = ""
    document.querySelectorAll(".schedule-date").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.airdate) {
                const date = DateTime.fromISO(e.dataset.airdate).setLocale(state.lang + "-CA").setZone(state.timezone)
                const fdate = date.toFormat(state.lang == Language.en ? "MMMM d" : "d MMMM")
                e.innerHTML = fdate
                if (prevDate != fdate) {
                    e.classList.remove("hidden")
                }
                prevDate = fdate;
            }
        }
    })

    document.querySelectorAll(".schedule-time").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.airdate) {
                const date = DateTime.fromISO(e.dataset.airdate).setLocale(state.lang + "-CA").setZone(state.timezone)
                e.innerHTML = date.toFormat(state.lang == Language.en ? "HH:mm" : "HH'h'mm")
            }
        }
    })

    document.querySelectorAll("#js-timezone-picker button").forEach(e => {
        if (e instanceof HTMLElement) {
            if (e.dataset.tzcode) {
                if (e.dataset.tzcode == state.timezone) {
                    e.classList.add("selected")
                }
            }
        }
    })
}

export const schedule_toggleAccordion = (index: number) => {
    const key = "cpacScheduleAccordion" + index + "IsOpen"
    let prev = ssg(key)
    if (prev == null) {
        prev = false
    }
    sss(key, !prev)
    schedule_updateAccordion()
}
export const schedule_updateAccordion = () => {
    let index = 1
    let proceed = true
    while (proceed) {
        const element = document.getElementById("schedule_accordion_item_" + index)
        if (element) {
            let isOpen = ssg("cpacScheduleAccordion" + index + "IsOpen")
            if (isOpen == null) isOpen = false
            element.setAttribute("class", isOpen ? "accordion accordion--showContent" : "accordion accordion--hideContent")
            index++
        } else {
            proceed = false
        }
    }
}