export const landingpage_toggleAccordion = (event: Event) => {
    event.preventDefault()
    document.querySelectorAll("#landing-page-holder .lp-abouts").forEach(e => {
        if (e instanceof HTMLElement) {
            const open = !e.classList.contains("closed")
            const newIsOpen = !open
            if (newIsOpen) {
                e.classList.remove("closed")
            } else {
                e.classList.add("closed")
            }
        }
    })
}