import {ItemListViewProps} from "../../types/ItemListViewProps.ts";
import GridHolder from "./GridStyles.tsx";
import {useEffect} from "react";
import {loadAppropriateImages} from "../../utils/image-utils.ts";

const Grid = (props: ItemListViewProps) => {

    useEffect(() => {
        loadAppropriateImages()
    }, []);

    const renderGridItems = () => {
        return props.itemList.items.map((item, i) => {
            if (item.image) {
                return (
                    <a key={`grid-item-${i}`} href={item.url} className="grid-item" itemProp='itemListElement'>
                        <img loading="lazy" className="multi-thumb" data-src={item.image} alt={item.altImageTxt} itemProp='image'/>
                    </a>
                )
            }
        });
    }

    return (
        <div itemScope itemType='https://schema.org/ItemList'>
            <GridHolder>
                {props.itemList.items &&
                    renderGridItems()
                }
            </GridHolder>
        </div>
    )
};

export default Grid;