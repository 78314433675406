import {ssg, sss} from "./common-utils.ts";

export const article_toggleAccordion = (index: number) => {
    const key = "cpacArticleAccordion" + index + "IsOpen"
    let prev = ssg(key)
    if (prev == null) {
        prev = false
    }
    sss(key, !prev)
    article_updateAccordion()
}
export const article_updateAccordion = () => {
    let index = 1
    let proceed = true
    while (proceed) {
        const element = document.getElementById("article_accordion_item_" + index)
        if (element) {
            let isOpen = ssg("cpacArticleAccordion" + index + "IsOpen")
            if (isOpen == null) isOpen = false
            element.setAttribute("class", isOpen ? "accordion accordion--showContent" : "accordion accordion--hideContent")
            index++
        } else {
            proceed = false
        }
    }
}