import styled from 'styled-components';

const FeaturedItemHolder = styled.div`

  .featured-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr /*repeat(3, 1fr)*/;
    grid-auto-rows: auto;
    gap: 10px;

    &__big-item {
      position: relative;
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      .jwplayer .jw-error-msg {
        align-items: center;
        background-color: transparent;
        padding: 0;
        width: 70%;
        top: 70%;
      }

      .jw-error-msg .jw-icon.jw-reset {
        display: none;
      }

      .jw-error-text {
        /* iphone 5/SE START */
        @media only screen and (max-width: 320px) {
          font-size: 12.1px;
        }
        /* iphone 5/SE END */
        @media only screen and (min-width: 321px) and (max-width: 1024px) {
          font-size: 15.6px;
        }
        height: 1.4em;
        overflow: hidden;
        font-size: 30px;
        background-color: black;
        opacity: 0.5;
        text-align: center;
      }

      &.only-item {
        grid-column: 1 / 4;
      }

      @media only screen and (max-width: 920px) {
        grid-column: 1 / 4;
      }

      &.wide {
        grid-column: 1 / 4;
      }

      &__live-label-holder {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
      }

      &__cpactv {
        position: absolute;
        top: 1px;
        right: 1px;
        background-color: black;
        color: white;
        font-weight: 700;
        width: 120px;
        height: 30px;
        z-index: 1;

        img {
          display: inline-block;
          height: 100%;
        }

        span {
          position: absolute;
          top: 5px;
        }
      }

    }

    &__small-item {
      position: relative;
      border: 1px solid #d2d9df;
      text-decoration: none;

      @media screen and (max-width: 520px) {
        grid-column: 1 / 4;
      }

      &__info {
        position: relative;
        min-height: 100%;
        background-color: white;

        &__header {
          position: relative;
          padding-top: calc((9 * var(--aspect-ratio-image-width)) / 16);
        }

        p {
          cursor: default;
        }

        a {
          &:hover {
            //color: black;
          }

          img {
            width: var(--aspect-ratio-image-width);
            height: calc((9 * var(--aspect-ratio-image-width)) / 16);
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
          }
        }

        &__category {
          display: block;
          text-decoration: none;
          padding: 10px 20px 0px 20px;
          font-weight: 700;
          color: #5f7586;
          text-transform: uppercase;
          margin: 0;
          font-size: 14px;
        }

        &__title {
          font-family: "Open Sans", sans-serif;
          display: block;
          text-decoration: none;
          padding: 0px 20px 20px 20px;
          color: #383838;
          font-weight: 500;
          font-size: 17px;
          line-height: 1.5em;
          margin: 0;
        }
      }

      &__live-info {
        position: absolute;
        bottom: 0px;
        font-family: "Open Sans", sans-serif;
        background: black;
        color: white;
        width: 100%;
        height: 30px;
        z-index: 1;
        text-align: center;
        font-weight: 900;
        opacity: 0.8;
        line-height: 28px;
        font-size: 15px;
      }

      &__cpactv {
        position: absolute;
        top: 0;
        right: 0;
        background-color: black;
        color: white;
        font-weight: 700;
        width: 120px;
        height: 30px;

        img {
          display: inline-block;
          width: auto !important;
          max-height: none !important;
          min-height: 0 !important;
          height: 100%;
        }

        span {
          position: absolute;
          top: 2px;
          right: 15px;
        }
      }

      .t-l {
        position: absolute;
        top: 2.5px;
        left: 2.5px;
        z-index: 1;
      }
    }
  }
`;

export default FeaturedItemHolder;