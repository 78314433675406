import React from "react";
import { useItemList } from "../utils/hooks.ts";
import HCarousel from "../itemlist-views/hcarousel/HCarousel.tsx";
import VCarousel from "../itemlist-views/vcarousel/VCarousel.tsx";
import FeaturedItems from "../itemlist-views/featureditems/FeaturedItems.tsx";
import Grid from "../itemlist-views/grid/Grid.tsx";
import HnCarousel from "../itemlist-views/hncarousel/HnCarousel.tsx";
import { getOrFail } from "../utils/common-utils.ts";
import ReactDOM from "react-dom/client";
import Loading from "../loading/Loading.tsx";

export type ItemListPropTypes = {
  pageLocalId: string;
  listName: string;
  fadeOutSeconds: number;
  itemId?: string;
};

function doRenderItemList(
  root: HTMLElement,
  path: string,
  name: string,
  fade: number,
  itemId: string,
) {
  // re-rendering - remove all children and start fresh
  while (root.childNodes.length > 0) {
    root.removeChild(root.childNodes[0]);
  }
  const holder = document.createElement("div");
  root.appendChild(holder);
  ReactDOM.createRoot(holder).render(
    <React.StrictMode>
      <ItemList
        pageLocalId={path}
        listName={name}
        fadeOutSeconds={fade}
        itemId={itemId}
      />
    </React.StrictMode>,
  );
}

export const renderItemList = (div: string) => {
  const root = document.getElementById(div);

  if (root) {
    const path = getOrFail(root.dataset, "localid");
    const name = getOrFail(root.dataset, "list");
    const fade = parseInt(getOrFail(root.dataset, "fade"));
    const itemId = root.dataset["itemid"];
    if (itemId == undefined) {
      return;
    }

    doRenderItemList(root, path, name, fade, itemId);
    setInterval(() => {
      if (
        root.childNodes.length == 0 ||
        root.childNodes[0].childNodes.length == 0
      ) {
        console.log("Livestream list lost. Re-rendering.");
        doRenderItemList(root, path, name, fade, itemId);
      }
    }, 2000);
  }
};

const ItemList = (props: ItemListPropTypes) => {
  const { pageLocalId, listName, fadeOutSeconds, itemId } = props;
  const { loading, list, error } = useItemList(listName, pageLocalId, itemId);

  if (loading) {
    return <Loading />;
  }
  if (error) {
    console.error("An error has occurred.", error);
    return;
  }
  if (list === undefined) {
    // console.debug("Nothing to render", {pageLocalId, listName})
    return;
  }

  // console.debug("About to render", {id, pageLocalId, listName, fadeOutSeconds, itemList, filteredItems})
  switch (list.viewmode) {
    case "h_carrousel_with_f_number":
      return <HCarousel itemList={list} fadeOutSeconds={fadeOutSeconds} />;
    case "v_carrousel_view":
      return <VCarousel itemList={list} />;
    case "h_carrousel_view":
      return <HnCarousel itemList={list} />;
    case "f_item_view":
      return <FeaturedItems itemList={list} />;
    case "grid":
      return <Grid itemList={list} />;
    default:
      console.warn("Unsupported ItemList view", list.viewmode);
      return;
  }
};

export default ItemList;
