import {useEffect, useState, useRef} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import UAParser from "ua-parser-js";
import YTVideoDetail from "./YTVideoDetail.tsx";
import YTVideoItem from "./YTVideoItem.tsx";
import {FaXmark} from "../../icons/faXmark.tsx";
import GetLabel from "../../language/GetLabel.tsx";

type PropTypes = {
    videos: any,
    selectedVideo: any,
    onVideoSelect: any,
    title: string,
    selected: any,
    playlistId: string
}
const YTVideoList = (props: PropTypes) => {
    const {videos, selectedVideo, onVideoSelect, title, selected, playlistId} = props;
    const [deviceType, setDeviceType] = useState<string>();
    const carouselContainer = useRef(null);

    const responsive = {
        desktop: {
            breakpoint: {max: 4000, min: 1024},
            items: 4,
            slidesToSlide: 4,
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 2,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1,
            slidesToSlide: 1,
        }
    };

    useEffect(() => {
        if (carouselContainer && carouselContainer.current) {
            const elements = document.querySelectorAll(`.${title && title.toLowerCase().replace(/\s/g, '')}-id .carousel-item`);
            elements.length > 0 && [].forEach.call(elements, function (li) {
                // @ts-ignore todo: figure out why ts doesn't like this
                li.setAttribute('aria-hidden', 'false');
            });
        }
    }, [videos]);

    useEffect(() => {
        const parser = new UAParser();
        const userAgent = navigator.userAgent;
        parser.setUA(userAgent);
        const result = parser.getResult();
        const deviceType = (result.device && result.device.type) || "desktop";
        setDeviceType(deviceType);

    }, []);

    const videoCloseHandlder = () => {
        onVideoSelect(null);
    }

    const showBigVideo = () => {
        return selectedVideo !== null && playlistId === selected;
    }

    return (
        <section className={`content__carousel ${showBigVideo() ? "expanded" : "constrain"}`} tabIndex={0} aria-label={title} itemType="https://schema.org/ItemList">
            <div className={"yt-video-list-holder"}>
                <h2 className="yt-video-list-title" itemProp='name'>{title}</h2>
                <button onClick={videoCloseHandlder} className={`close-btn ${showBigVideo() ? "show" : "hidden"}`}><GetLabel name="CLOSE"/><FaXmark/></button>
                <YTVideoDetail video={selectedVideo} selectedVideo={selectedVideo} selected={showBigVideo()} className={`${showBigVideo() ? "show" : "hidden"}`}/>
                <Carousel
                    deviceType={deviceType}
                    responsive={responsive}
                    arrows={true}
                    //showDots={videos && videos.length > 5 && deviceType == "desktop" ? true : false}
                    transitionDuration={500} autoPlaySpeed={1000}
                    containerClass={`carousel-container ${title && title.toLowerCase().replace(/\s/g, '')}-id`}
                    itemClass="carousel-item"
                    dotListClass="dot-list-style"
                    keyBoardControl={true}
                    focusOnSelect={true}
                    ref={carouselContainer}>
                    {videos && videos.map((video: any) => {
                        return <YTVideoItem key={video.snippet.resourceId.videoId} onVideoSelect={onVideoSelect} video={video}/>
                    })}
                </Carousel>
            </div>
        </section>
    )
}

export default YTVideoList;