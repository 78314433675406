import {getLabel} from "../language/lang-utils.ts";

const Loading = () => {
    return (
        <div className={"loading-parent"}>
            <div className={"loading-child"}>
                <div className='loading' aria-label={getLabel("LOADING")}></div>
            </div>
        </div>
    );
};

export default Loading;