import { VideoPlayerDetails } from "../../types/VideoPlayerDetails.ts";
import { getLanguage } from "../../language/lang-utils.ts";
import React, { useEffect, useRef, useState } from "react";
import store from "../../cpac-store.ts";
import { truncateWords } from "../../utils/search-utils.ts";
import { FaPlay } from "../../icons/faPlay";
import { FaArrowLeft } from "../../icons/faArrowLeft";
import { FaArrowRight } from "../../icons/faArrowRight";
import SharePageReact from "../../share/SharePageReact.tsx";
import { ReactJWPlayerContainer } from "./ReactJWPlayerContainer";
import { ReactJWPlayerPlaylist } from "../../types/ReactJWPlayerPlaylist.ts";
import { Tag } from "../../types/Tag.ts";
import { loadAppropriateImages } from "../../utils/image-utils.ts";
import { DateTime, Duration } from "luxon";
import RelativeTime from "../../date/RelativeTime.tsx";
import LiveCountdown from "../../date/LiveCountdown.tsx";
import ReactDOM from "react-dom/client";
import GetLabel from "../../language/GetLabel.tsx";
import { fetchEpisodeInfo } from "../../utils/backend.ts";
import { config } from "../../config.ts";

type VideoPropTypes = {
  id: string;
  details: VideoPlayerDetails;
  isLanding: boolean;
  processPrelive: boolean;
  hOnlyItem: boolean;
  setShowCPACTV?: React.Dispatch<React.SetStateAction<boolean>>;
  tags?: Tag[];
};

export const renderVideo = (div: string) => {
  const root = document.getElementById(div);
  if (root == null) return;
  const landing = root.dataset["islanding"] == "true";
  const processprelive = root.dataset["processprelive"] == "true";
  const details = VideoPlayerDetails.createInstanceFromDOM(
    root.dataset,
    getLanguage(),
  );
  const tags = Tag.createArrayFromDOM(root.dataset, getLanguage());
  ReactDOM.createRoot(root).render(
    <React.StrictMode>
      <Video
        id={div}
        details={details}
        isLanding={landing}
        hOnlyItem={false}
        processPrelive={processprelive}
        tags={tags}
      />
    </React.StrictMode>,
  );
};

const Video = (props: VideoPropTypes) => {
  const [videoLang, setVideoLang] = useState<string | undefined>();
  const [playlist, setPlaylist] = useState<ReactJWPlayerPlaylist>();
  const [isInVideoPage, setIsInVideoPage] = useState(false);
  const [isInLandingPage, setIsInLandingPage] = useState(false);
  const [isLiveNow, setIsLiveNow] = useState(false);
  const [newUrl, setNewUrl] = useState(null);
  const [audioButtons, setAudioButtons] = useState(null);
  const [btnClicked, setBtnClicked] = useState(false);
  const thumbContainer = useRef(null);
  const [lastDateModified, setLastDateModified] = useState< DateTime | undefined >();
  const [isWide, setIsWide] = useState(false);

  const startDurationArr =
    props && props.details && props.details.videoDuration
      ? props.details.videoDuration.split(":")
      : undefined;
  let isoDuration = null;
  if (startDurationArr) {
    const hrs = parseInt(startDurationArr[0]);
    const mins = parseInt(startDurationArr[1]);
    const secs = parseInt(startDurationArr[2]);
    isoDuration = Duration.fromObject({
      hours: hrs,
      minutes: mins,
      seconds: secs,
    }).toISO();
  }

  useEffect(() => {
    const tmpPlayList: ReactJWPlayerPlaylist = {
      title: props.details.title,
      description: props.details.description,
      category: props.details.category,
      image: props.details.image,
      file: newUrl !== null ? newUrl : props.details.videoUrl,
    };
    if (props.details.videoUrl) {
      setPlaylist(tmpPlayList);
      const lang = store.getState().lang.toString();
      if (!videoLang) {
        setVideoLang(lang);
      }
    }
    if (props.details.type !== "cpactv") {
      if (
        props.details.liveDateTime &&
        props.details.lastDateModified &&
        props.details.liveDateTime > props.details.lastDateModified
      ) {
        // @ts-ignore
        setLastDateModified(props.details.liveDateTime);
      } else {
        // @ts-ignore
        setLastDateModified(props.details.lastDateModified);
      }
    }

    window.location.search !== "" && window.location.search !== undefined
      ? setIsInVideoPage(true)
      : setIsInVideoPage(false);
    props.isLanding ? setIsInLandingPage(true) : setIsInLandingPage(false);
  }, [props.details, props.isLanding, newUrl]);

  useEffect(() => {
    loadAppropriateImages();
  });

  const audioClickedCB = (clicked: boolean) => {
    setBtnClicked(clicked);
  };

  const audioLangClickHandler = (lang: string) => {
    setVideoLang("no-lang");
    setBtnClicked(true);

    setTimeout(() => {
      if (lang !== "en" && lang !== "fr") {
        setVideoLang("Floor");
      } else {
        setVideoLang(lang);
      }
    }, 50);
  };

  const renderTags = () => {
    if (props.tags) {
      return props.tags.map((tag: Tag, index: number) => {
        return (
          <a href={tag.url} key={index}>
            <div className="article-tag">
              <img
                alt="tag icon"
                className="tag-icon"
                src="/static-assets/theme/svg/tag.svg"
              />
              <span itemProp="keywords">{tag.name}</span>
            </div>
          </a>
        );
      });
    }
  };

  const renderAudioBtns = () => {
    if (audioButtons) {
      // @ts-ignore
      return audioButtons.map((button, i) => {
        return (
          <button
            key={`audio-btn-${i}`}
            onClick={() => audioLangClickHandler(button.lang)}
          >
            <FaPlay />
            {button.name !== "English" && button.name !== "French" ? (
              <GetLabel name="FLOOR" />
            ) : (
              <GetLabel name={button.name.toUpperCase()} />
            )}
          </button>
        );
      });
    }
  };

  const renderWide = () => {
    if (!isWide) {
      return (
        <>
          <span>
            <GetLabel name={"WIDE_PLAYER"} />
          </span>
          <FaArrowRight />
        </>
      );
    } else {
      return (
        <>
          <span>
            <GetLabel name={"CONSTRAIN_PLAYER"} />
          </span>
          <FaArrowLeft />
        </>
      );
    }
  };

  const toggleWide = () => {
    setIsWide((isWide) => !isWide); // needed to track & trigger rerender
    const el = document.getElementById(props.id); // this div is outside the component, so need to rerender ourselves
    if (el != null) {
      if (el.classList.contains("wide")) {
        el.classList.remove("wide");
      } else {
        el.classList.add("wide");
      }
    }
  };
  //description render
  const renderDescription = () => {
    const regX = /\n/g;
    const fixedDescription = props.details.description
      ? props.details.description.replace(regX, "<br>")
      : "";
    return isInVideoPage ? (
      <p
        className="description"
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: fixedDescription }}
      ></p>
    ) : isInLandingPage ? (
      <p
        className="description-landing"
        tabIndex={0}
        dangerouslySetInnerHTML={{ __html: fixedDescription }}
      ></p>
    ) : (
      <p
        className="description description__container-relative"
        tabIndex={0}
        itemProp="description"
      >
        {props.details.description &&
          truncateWords(props.details.description, 225)}
        {props.details.description &&
          props.details.description.length > 225 && (
            <span
              className="description__ellipsis"
              onClick={() => (window.location.href = props.details.url)}
            >
              (...)
            </span>
          )}
      </p>
    );
  };

  const minutesBefore = config.liveNotificationTime;
  const alertTime = props.details.liveDateTime.minus(minutesBefore);
  const eventIsSoon = DateTime.now() > alertTime && DateTime.now() < props.details.liveDateTime ? true : false;
  const programID = props.details.categoryURL.replace("/program?id=", "");
  const pgmIDs: String[] = ["7", "23", "51", "63"];
  let liveNow = false;

  const renderCounter = () => {
    const liveTimer = setInterval(() => {
      const now = DateTime.now()
      const liveInFive = config.liveInFive;
      const liveInTwo = config.liveInTwo;
      if (pgmIDs.includes(programID)) {
        liveNow = now > props.details.liveDateTime.minus(liveInTwo) ? true : false;
      } else {
        liveNow = now > props.details.liveDateTime.minus(liveInFive) ? true : false;
      }

      if (liveNow) {
        setIsLiveNow(liveNow);
        fetchEpisodeInfo(props.details.episodeId).then((data) => {
          setNewUrl(data.component.details.videoUrl);
        });
        clearInterval(liveTimer);
      }
    }, 1000);

    if (liveNow){
      return; 
    } else {
      return (
        <div className="live-info">
          <GetLabel name={"LIVE"} />{" "}
          {
          eventIsSoon ? 
          <RelativeTime date={props.details.liveDateTime} />
            :
          <LiveCountdown liveDateTime={props.details.liveDateTime} />
          }
        </div>
      );
    }
  };

  const renderJWPlayer = () => {
    if (
      props.processPrelive &&
      props.details.type === "prelive" &&
      !isLiveNow &&
      newUrl === null
    ) {
      return (
        <div className="video-thumb-container" ref={thumbContainer}>
          <div className="video-thumb-header">
            <img
              className="video-thumb multi-thumb"
              loading="lazy"
              data-src={props.details.image}
              alt={props.details.altImageTxt}
              itemProp="thumbnailUrl"
            />
            {renderCounter()}
          </div>
        </div>
      );
    } else {
      if (playlist && playlist.file && videoLang) {
        return (
          <div itemProp="video">
            <meta itemProp="thumbnailUrl" content={props.details.image} />
            <ReactJWPlayerContainer
              requestedVideoLang={videoLang}
              playlist={playlist}
              setShowCPACTV={
                isInVideoPage && isInLandingPage ? null : props.setShowCPACTV
              }
              autoplay={false}
              setAudiosCB={(audioList: any) => setAudioButtons(audioList)}
              btnClicked={btnClicked}
              audioClickedCB={(clicked: any) => audioClickedCB(clicked)}
              vType={props.details.type}
            />
          </div>
        );
      }
    }
  };

  const videoSchemaType = () => {
    return props.details.type !== "cpactv"
      ? "https://schema.org/VideoObject"
      : "https://schema.org/Episode";
  };
  const lastModDateStr =
    lastDateModified === undefined || lastDateModified === null
      ? null
      : lastDateModified.toISO();
  return (
    <div id="video-player-holder">
      <div itemScope itemType={videoSchemaType()}>
        <meta itemProp="name" content={props.details.title} />
        <meta itemProp="description" content={props.details.description} />
        {props.processPrelive && props.details.type === "live" && (
          <meta
            itemType="https://schema.org/BroadcastEvent"
            itemProp="isLiveBroadcast"
          />
        )}
        {renderJWPlayer()}
        {lastModDateStr && (
          <meta itemProp="uploadDate" content={lastModDateStr} />
        )}
        <div className="video-info">
          <div
            className={`video-info__btns-cont ${
              isInLandingPage ? "centered" : ""
            }`}
          >
            {renderAudioBtns()}
            <button
              className={isInLandingPage || props.hOnlyItem ? "hide" : ""}
              onClick={toggleWide}
            >
              {renderWide()}
            </button>
          </div>
          <div className="video-info__details-cont">
            <meta
              itemProp={`${
                props.details.type !== "cpactv" ? "contentUrl" : "url"
              }`}
              content={props.details.videoUrl}
            />
            {isoDuration && <meta itemProp="duration" content={isoDuration} />}
            <div itemScope itemType="https://schema.org/ReportageNewsArticle">
              
              {props.details.type === "cpactv" ?
                <a href={props.details.url} className="category">
                  <span itemProp="isPartOf">{props.details.category}</span>
                </a>
              :
                <a href={props.details.categoryURL} className={`category ${isInLandingPage ? "hide" : ""}`}>
                  <span itemProp="isPartOf">{props.details.category}</span>
                </a>
              }
                
              {props.details.type !== "prelive" && !isInVideoPage &&  !isInLandingPage ?
               <a href={props.details.url} className={`title ${isInVideoPage ? "in-video-page" : isInLandingPage ? "in-landing-page" : ""}`} tabIndex={0}>
                  <span itemProp="headline">{props.details.title}</span>
               </a>
               :
               <span className={`title ${isInVideoPage ? "in-video-page" : isInLandingPage ? "in-landing-page" : ""}`} tabIndex={0}> 
                 <span itemProp="headline">{props.details.title}</span>
               </span>
              }

              <meta
                itemProp="articleBody"
                content={props.details.description}
              />
              <meta itemProp="image" content={props.details.image} />
              {renderDescription()}
            </div>
          </div>
          {props.tags && props.tags.length > 0 && (
            <div className="article-tag-container">{renderTags()}</div>
          )}
          {isInVideoPage && !isInLandingPage && <SharePageReact />}
        </div>
      </div>
    </div>
  );
};

export default Video;
